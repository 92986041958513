import {
  is,
  isArrayOf,
  isBoolean,
  isEither,
  isNull,
  isNumber,
  isObjectOf,
  isOptional,
  isShapeOf,
  isString,
  isUndefined,
  type TypePredicate,
} from 'giltig';

import type {
  PersistedExperience,
  StudioExperience,
} from '@studio/core-common/utils/experience';
import createEnumValidator from '@studio/utils/createEnumValidator';

import {
  type CmsBookmarks,
  type CmsBookmarksV1,
  type CmsBookmarksV2,
  type CmsEditorHistory,
  type InfoPanelPreference,
  type InitialContentSubset,
  type InitialProductLine,
  type InitialPublishingSite,
  type MultiverseStorage,
  type RecentlyViewed,
  type RecentlyViewedEntry,
  RecentlyViewedEntryType,
  type RegionPreference,
  type ResourceIdentifier,
  type SavedItem,
  type SavedItemResource,
  type SavedItems,
  SavedItemType,
  type SavedItemView,
  type TablePreference,
  type TableStorage,
  type TableStorageTable,
  type TimeZonePreference,
} from './types';

export const isStudioExperience: TypePredicate<StudioExperience> = isEither(
  is('max'),
  is('bleacherreport'),
  is('discoveryplus'),
);

const isSecondaryExperience = isShapeOf({
  subdivisionTenant: isEither(isString, isNull, isUndefined),
  subdivisionMarket: isEither(isString, isNull, isUndefined),
  commerceLine: isEither(isString, isNull, isUndefined),
  productLine: isEither(isString, isNull, isUndefined),
  publishingSite: isEither(isString, isNull, isUndefined),
});

export const isPersistedExperience: TypePredicate<PersistedExperience> =
  isShapeOf({
    studioExperience: isStudioExperience,
    secondaryExperiences: isObjectOf(isSecondaryExperience),
  });

export const isRegionPreference: TypePredicate<RegionPreference> = isShapeOf({
  region: isString,
});

export const isTimeZonePreference: TypePredicate<TimeZonePreference> = isEither(
  isShapeOf({ type: is('realm') }),
  isShapeOf({ type: is('local') }),
  isShapeOf({ type: is('custom'), timeZone: isString }),
);

export const isTablePreference: TypePredicate<TablePreference> = isShapeOf({
  rowsPerPage: isNumber,
});

export const isRecentlyViewedEntry: TypePredicate<RecentlyViewedEntry> =
  isShapeOf({
    type: createEnumValidator(RecentlyViewedEntryType),
    id: isString,
    title: isString,
    timestamp: isString,
    studioExperience: isOptional(isString),
  });

export const isRecentlyViewed: TypePredicate<RecentlyViewed> = isShapeOf({
  entries: isArrayOf(isRecentlyViewedEntry),
});

export const isSavedItemView: TypePredicate<SavedItemView> = isShapeOf({
  type: is(SavedItemType.ContentView),
  query: isString,
  label: isString,
  pinned: isBoolean,
});

export const isSavedItemResource: TypePredicate<SavedItemResource> = isShapeOf({
  type: isEither(
    is(SavedItemType.Program),
    is(SavedItemType.Series),
    is(SavedItemType.Season),
  ),
  id: isString,
  label: isString,
  pinned: isBoolean,
});

export const isSavedItem: TypePredicate<SavedItem> = isEither(
  isSavedItemView,
  isSavedItemResource,
);

export const isSavedItems: TypePredicate<SavedItems> = isShapeOf({
  savedItems: isArrayOf(isSavedItem),
});

export const isInfoPanelPreference: TypePredicate<InfoPanelPreference> =
  isShapeOf({
    collapsed: isBoolean,
  });

export const isCmsBookmarksV1: TypePredicate<CmsBookmarksV1> = isShapeOf({
  version: is(1),
  bookmarks: isObjectOf(isObjectOf(isBoolean)),
});

export const isCmsBookmarksV2: TypePredicate<CmsBookmarksV2> = isShapeOf({
  version: is(2),
  bookmarks: isArrayOf(
    isShapeOf({
      id: isString,
      type: isString,
      publishingSiteId: isEither(isString, isNull),
    }),
  ),
});

export const isCmsBookmarks: TypePredicate<CmsBookmarks> = isEither(
  isCmsBookmarksV1,
  isCmsBookmarksV2,
);

export const isResourceIdentifier: TypePredicate<ResourceIdentifier> =
  isShapeOf({
    id: isString,
    type: isString,
  });

export const isCmsEditorHistory: TypePredicate<CmsEditorHistory> = isShapeOf({
  version: is(1),
  recentlyOpened: isArrayOf(isResourceIdentifier),
});

export const isInitialPublishingSite: TypePredicate<InitialPublishingSite> =
  isShapeOf({
    publishingSiteId: isString,
  });

export const isInitialProductLine: TypePredicate<InitialProductLine> =
  isShapeOf({
    productLineId: isString,
  });

export const isInitialContentSubset: TypePredicate<InitialContentSubset> =
  isShapeOf({
    contentSubsetId: isString,
  });

export const isMultiverseStorage: TypePredicate<MultiverseStorage> = isShapeOf({
  allowList: isArrayOf(isString),
  autoEnroll: isArrayOf(isString),
  releaseEnroll: isArrayOf(isString),
});

export const isTableStorageTable: TypePredicate<TableStorageTable> = isShapeOf({
  columnVisibilityModel: isObjectOf(isBoolean),
});

export const isTableStorage: TypePredicate<TableStorage> = isShapeOf({
  tables: isObjectOf(isTableStorageTable),
});
