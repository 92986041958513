import {
  memo,
  type ReactElement,
  type ReactNode,
  useContext,
  useMemo,
} from 'react';

import type { AccessFn } from '@studio/api/access';
import customAttributesApi from '@studio/api/custom-attributes';
import type { CurrentUser } from '@studio/api/users';
import { Redirect } from '@studio/router';
import Spinner from '@studio/ui-common/Spinner';
import {
  type RequestContext,
  RequestContextProvider,
  useRequestContext_UNSAFE,
} from '@studio/utils/requestContext';
import useDeferredValueWithPending from '@studio/utils/useDeferredValueWithPending';
import useEventCallback from '@studio/utils/useEventCallback';
import useLastEqualValue from '@studio/utils/useLastEqualValue';

import * as appRoutes from '../../appRoutes';
import {
  createExperienceAuthorizationChecker,
  createExperienceRoleResolver,
  type Experience,
  type ExperienceAuthorizationChecker,
  type ExperienceConfig,
  type ExperienceRoleResolver,
  getEligibleExperiences,
  type PersistedExperience,
  type SecondaryExperienceKey,
  type VerifiedExperience,
} from '../../utils/experience';
import type { PermissionGroups } from '../../utils/permission';
import useAccessContext from '../../utils/useAccessContext';
import useCustomAttributes, {
  type UpdateAttributes,
} from '../../utils/useCustomAttributes';
import { SessionStateStatus, useSessionState } from '../SessionManager';
import ExperienceContext, {
  type ExperienceContextValue,
} from './ExperienceContext';
import { getNextRequestContext } from './utils';

const canAlwaysAccess: AccessFn = () => true;

const EMPTY_ARRAY: readonly never[] = [];

export interface ExperienceManagerProps {
  access?: AccessFn;
  enabledExperiences?: readonly SecondaryExperienceKey[];
  noVerifiedExperienceBehavior?: 'redirectToRoot' | 'enterWithNullExperience';
  children: ReactNode;
}

export default function ExperienceManager(
  props: ExperienceManagerProps,
): ReactNode {
  const sessionState = useSessionState();

  if (
    sessionState.status !== SessionStateStatus.LoggedIn ||
    sessionState.experienceConfig == null ||
    sessionState.permissionGroups == null
  ) {
    return props.children;
  }

  const { experienceConfig, permissionGroups, user } = sessionState;

  return (
    <ExperienceManagerInner
      {...props}
      experienceConfig={experienceConfig}
      permissionGroups={permissionGroups}
      user={user}
    />
  );
}

interface ExperienceManagerInnerProps extends ExperienceManagerProps {
  experienceConfig: ExperienceConfig;
  permissionGroups: PermissionGroups;
  user: CurrentUser;
}

const ExperienceManagerInner = memo(function ExperienceManagerInner({
  access,
  enabledExperiences = EMPTY_ARRAY,
  ...props
}: ExperienceManagerInnerProps): ReactElement {
  const parentExperienceConfig = useContext(ExperienceContext);

  const parentAccess = parentExperienceConfig?.access;
  const parentEnabledExperiences = parentExperienceConfig?.enabledExperiences;

  access ??= parentAccess ?? canAlwaysAccess;
  enabledExperiences = useLastEqualValue(
    useMemo(() => {
      if (parentEnabledExperiences == null) {
        return enabledExperiences.toSorted();
      }

      return new Set([...parentEnabledExperiences, ...enabledExperiences])
        .values()
        .toArray()
        .sort();
    }, [enabledExperiences, parentEnabledExperiences]),
  );

  const [
    pendingPersistedExperienceTransition,
    [
      persistedExperience,
      updatePersistedExperience,
      {
        loading: loadingPersistedExperience,
        updating: updatingPersistedExperience,
      },
    ],
  ] = useDeferredValueWithPending(
    useCustomAttributes(customAttributesApi.experience, {
      // TODO: Enable broadcast once we move the current experience state to the URL
      skipBroadcast: true,
    }),
  );

  return (
    <>
      <Spinner overlay show={loadingPersistedExperience} />

      {!loadingPersistedExperience && (
        <ExperienceManagerInnerInner
          {...props}
          access={access}
          enabledExperiences={enabledExperiences}
          persistedExperience={persistedExperience}
          onUpdatePersistedExperience={updatePersistedExperience}
          updatingPersistedExperience={
            updatingPersistedExperience || pendingPersistedExperienceTransition
          }
        />
      )}
    </>
  );
});

interface ExperienceManagerInnerInnerProps extends ExperienceManagerInnerProps {
  access: AccessFn;
  enabledExperiences: readonly SecondaryExperienceKey[];
  persistedExperience: PersistedExperience | null;
  updatingPersistedExperience: boolean;
  onUpdatePersistedExperience: UpdateAttributes<PersistedExperience, null>;
}

const ExperienceManagerInnerInner = memo(function ExperienceManagerInnerInner({
  user,
  persistedExperience,
  permissionGroups,
  access,
  enabledExperiences,
  experienceConfig,
  updatingPersistedExperience,
  onUpdatePersistedExperience,
  noVerifiedExperienceBehavior = 'redirectToRoot',
  children,
}: ExperienceManagerInnerInnerProps) {
  const accessContext = useAccessContext({
    resolveExperienceRoles: false,
  });

  const unverifiedExperience = useMemo<Experience>(() => {
    if (persistedExperience?.studioExperience == null) {
      return {};
    }

    return {
      studioExperience: persistedExperience.studioExperience,
      ...persistedExperience.secondaryExperiences[
        persistedExperience.studioExperience
      ],
    };
  }, [persistedExperience]);

  const resolveExperienceRoles = useMemo<ExperienceRoleResolver>(() => {
    return createExperienceRoleResolver(
      user.legacyRoles,
      user.groups,
      permissionGroups,
    );
  }, [permissionGroups, user.groups, user.legacyRoles]);

  const isAuthorizedExperience = useMemo<ExperienceAuthorizationChecker>(() => {
    return createExperienceAuthorizationChecker(
      access,
      accessContext,
      resolveExperienceRoles,
    );
  }, [access, accessContext, resolveExperienceRoles]);

  const verifiedExperience = useMemo<VerifiedExperience | null>(() => {
    using eligibleExperiences = getEligibleExperiences(
      unverifiedExperience,
      experienceConfig,
      enabledExperiences,
    );

    return eligibleExperiences.find(isAuthorizedExperience) ?? null;
  }, [
    enabledExperiences,
    experienceConfig,
    isAuthorizedExperience,
    unverifiedExperience,
  ]);

  const prevRequestContext = useRequestContext_UNSAFE();

  const nextRequestContext = useMemo<RequestContext>(
    () => getNextRequestContext(prevRequestContext, verifiedExperience),
    [prevRequestContext, verifiedExperience],
  );

  const updateExperience = useEventCallback((nextExperience: Experience) => {
    const {
      studioExperience: nextStudioExperience,
      ...nextSecondaryExperiences
    } = nextExperience;

    if (nextStudioExperience == null) {
      throw new TypeError("You can't clear your Studio experience!");
    }

    onUpdatePersistedExperience(null, prevPersistedExperience => {
      return {
        studioExperience: nextStudioExperience,
        secondaryExperiences: {
          ...prevPersistedExperience?.secondaryExperiences,
          [nextStudioExperience]: {
            ...prevPersistedExperience?.secondaryExperiences[
              nextStudioExperience
            ],
            ...nextSecondaryExperiences,
          },
        },
      };
    });
  });

  const nextExperienceContextValue = useMemo<ExperienceContextValue>(
    () => ({
      access,
      experience: verifiedExperience,
      experienceConfig,
      enabledExperiences,
      updatingExperience: updatingPersistedExperience,
      updateExperience,
    }),
    [
      access,
      verifiedExperience,
      experienceConfig,
      enabledExperiences,
      updatingPersistedExperience,
      updateExperience,
    ],
  );

  if (
    verifiedExperience === null &&
    noVerifiedExperienceBehavior === 'redirectToRoot'
  ) {
    return <Redirect to={appRoutes.portalRoot()} />;
  }

  return (
    <ExperienceContext.Provider value={nextExperienceContextValue}>
      <RequestContextProvider value={nextRequestContext}>
        {children}
      </RequestContextProvider>
    </ExperienceContext.Provider>
  );
});
