import {
  isArrayOf,
  isNumber,
  isOptional,
  isShapeOf,
  isString,
  type PredicatedType,
} from 'giltig';
import {
  type BrowserHistoryBuildOptions,
  createBrowserHistory,
  createLocation,
  createMemoryHistory,
  createPath,
  type History,
  type Href,
  type Location,
  type LocationDescriptor,
  locationsAreEqual,
  type MemoryHistoryBuildOptions,
} from 'history';

import hasOwn from '@studio/utils/hasOwn';

import { resolveLocation, type To } from './utils';

const HYBRID_HISTORY_SYMBOL = Symbol('HYBRID_HISTORY');

const isAny = (_value: unknown): _value is any => true;

const isHistoryEntries = isShapeOf({
  index: isNumber,
  entries: isArrayOf(
    isShapeOf<Location>({
      pathname: isString,
      hash: isString,
      search: isString,
      state: isAny,
      key: isOptional(isString),
    }),
  ),
});

type HistoryEntries = PredicatedType<typeof isHistoryEntries>;

const PERSISTED_HISTORY_KEY = 'history';

function persistHistoryEntries(historyEntries: HistoryEntries) {
  sessionStorage.setItem(PERSISTED_HISTORY_KEY, JSON.stringify(historyEntries));
  return historyEntries;
}

function getPersistedHistoryEntries() {
  try {
    const rawData = sessionStorage.getItem(PERSISTED_HISTORY_KEY);
    if (rawData === null) {
      return null;
    }
    const data = JSON.parse(rawData);
    if (isHistoryEntries(data)) {
      return data;
    } else {
      return null;
    }
  } catch {
    return null;
  }
}

function restorePersistedHistory(history: History) {
  let historyEntries = getPersistedHistoryEntries();

  if (
    historyEntries?.entries[historyEntries.index] === undefined ||
    !locationsAreEqual(
      historyEntries.entries[historyEntries.index]!,
      history.location,
    )
  ) {
    historyEntries = { entries: [history.location], index: 0 };
    persistHistoryEntries(historyEntries);
  }

  return historyEntries;
}

interface ReadonlyMemoryHistory extends History {
  readonly index: number;
  readonly entries: readonly Readonly<Location>[];
}

type LocationMatcher = (
  location: Readonly<Location>,
  popCount: number,
) => boolean;

function createPathLocationMatcher(to: LocationDescriptor): LocationMatcher {
  const { pathname } = createLocation(to);
  return location => location.pathname === pathname;
}

export interface HybridHistory extends ReadonlyMemoryHistory {
  /** @deprecated Do not use this directly, prefer {@see useNavigate} */
  push: History['push'];
  /** @deprecated Do not use this directly, prefer {@see useNavigate} */
  replace: History['replace'];
  /** @deprecated Do not use this directly, prefer {@see useNavigate} */
  pop(to: LocationDescriptor, locationMatcher?: LocationMatcher): void;
  createPopHref(
    to: LocationDescriptor,
    locationMatcher?: LocationMatcher,
  ): string;
  getLocation(): Location;
}

export function createHref(history: HybridHistory, to: To): Href {
  return history.createHref(resolveLocation(history.location, to));
}

export function decorateHistory(
  history: ReadonlyMemoryHistory,
): asserts history is HybridHistory {
  function findPopIndex(locationMatcher: LocationMatcher) {
    // Look backwards, starting at the current index, for the first matching entry
    let popIndex = history.index;
    while (
      popIndex >= 0 &&
      !locationMatcher(history.entries[popIndex]!, popIndex - history.index)
    ) {
      popIndex -= 1;
    }
    return popIndex;
  }

  function createPopHref(
    locationDescriptor: LocationDescriptor,
    locationMatcher = createPathLocationMatcher(locationDescriptor),
  ) {
    const popIndex = findPopIndex(locationMatcher);
    if (popIndex === -1) {
      const path =
        typeof locationDescriptor === 'string'
          ? locationDescriptor
          : createPath(locationDescriptor);

      return path;
    }
    return history.createHref(history.entries[popIndex]!);
  }

  function pop(
    locationDescriptor: LocationDescriptor,
    locationMatcher = createPathLocationMatcher(locationDescriptor),
  ) {
    const popIndex = findPopIndex(locationMatcher);
    if (popIndex === -1) {
      const [path, state] =
        typeof locationDescriptor === 'string'
          ? [locationDescriptor, undefined]
          : [createPath(locationDescriptor), locationDescriptor.state];

      history.push(path, state);
    } else {
      history.go(popIndex - history.index);
    }
  }

  function getLocation() {
    return history.location;
  }

  Object.assign(history, {
    [HYBRID_HISTORY_SYMBOL]: true,
    createPopHref,
    pop,
    getLocation,
  });
}

export function isHybridHistory(
  history: History | null | undefined,
): history is HybridHistory {
  return history != null && hasOwn(history, HYBRID_HISTORY_SYMBOL);
}

type GetConfirmation = (
  message: string | undefined,
  callback: (result: boolean) => void,
) => void;

const getDefaultUserConfirmation: GetConfirmation = (message, callback) => {
  callback(window.confirm(message));
};

let getCustomUserConfirmation: GetConfirmation | undefined = undefined;

/**
 * Temporarily redefine getUserConfirmation, so that we can control when the callback is called.
 * CAREFUL!! If you don't change it back to undefined, you WILL get unexpected behavior.
 * Only to be used by useBlocker.
 */
export function setCustomUserConfirmation(
  getCustomConfirmation: GetConfirmation | undefined,
): void {
  getCustomUserConfirmation = getCustomConfirmation;
}

export function createHybridBrowserHistory(
  options?: BrowserHistoryBuildOptions,
): HybridHistory {
  const rawHistory = createBrowserHistory({
    ...options,
    getUserConfirmation(message, callback) {
      const getConfirmation =
        getCustomUserConfirmation ??
        options?.getUserConfirmation ??
        getDefaultUserConfirmation;

      getConfirmation(message, callback);
    },
  });

  const history = Object.assign(
    rawHistory,
    restorePersistedHistory(rawHistory),
  );

  history.listen((location, action) => {
    if (action === 'PUSH') {
      history.index += 1;
      history.entries[history.index] = location;
      history.entries.length = history.index + 1;
    } else if (action === 'POP') {
      // Look backwards, starting at the end, for the first matching location
      history.index = history.entries.length - 1;
      while (
        history.index >= 0 &&
        !locationsAreEqual(history.entries[history.index]!, location)
      ) {
        history.index -= 1;
      }
      // If we couldn't find the entry, reset the list
      if (history.index === -1) {
        history.index = 0;
        history.entries[history.index] = location;
        history.entries.length = history.index + 1;
      }
    } else if (action === 'REPLACE') {
      history.entries[history.index] = location;
    }

    const { index, entries } = history;
    persistHistoryEntries({ index, entries });
  });

  decorateHistory(history);

  return history;
}

export function createHybridMemoryHistory(
  options?: MemoryHistoryBuildOptions,
): HybridHistory {
  const memoryHistory = createMemoryHistory({
    ...options,
    getUserConfirmation(message, callback) {
      const getConfirmation =
        getCustomUserConfirmation ??
        options?.getUserConfirmation ??
        getDefaultUserConfirmation;

      getConfirmation(message, callback);
    },
  });

  decorateHistory(memoryHistory);

  return memoryHistory;
}
