import { useMemo } from 'react';
import { useLocation } from 'react-router';

import useEventCallback from '@studio/utils/useEventCallback';

import useNavigate from './useNavigate';

export type SearchParams = Readonly<Record<string, string>>;
type NextSearchParams = ConstructorParameters<typeof URLSearchParams>[0];

type SetParamsFromPrevFn = (prevSearchParams: SearchParams) => NextSearchParams;
type SetSearchParamsArg = NextSearchParams | SetParamsFromPrevFn;

export type SetParamsFn = (params: SetSearchParamsArg) => void;

export default function useSearchParams(): readonly [
  searchParams: SearchParams,
  setSearchParams: SetParamsFn,
] {
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = useMemo<SearchParams>(
    () => Object.fromEntries(new URLSearchParams(location.search)),
    [location.search],
  );

  const setSearchParams = useEventCallback(
    (
      nextSearchParams:
        | NextSearchParams
        | ((prevSearchParams: SearchParams) => NextSearchParams),
    ) => {
      navigate(
        prevLocation => {
          const nextUrlSearchParams = new URLSearchParams(
            typeof nextSearchParams === 'function'
              ? nextSearchParams(
                  Object.fromEntries(new URLSearchParams(prevLocation.search)),
                )
              : nextSearchParams,
          );

          return { search: nextUrlSearchParams.toString() };
        },
        { mode: 'replace' },
      );
    },
  );

  return [searchParams, setSearchParams] as const;
}
