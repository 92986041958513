import type { Preferences } from '@studio/api/custom-attributes';
import type { Settings } from '@studio/api/settings';
import { type CurrentUser, MfaAction, MfaStatus } from '@studio/api/users';
import type { SubdivisionStrategies } from '@studio/api/utils/subdivision';
import type { TaggedUnion } from '@studio/utils/types';

import type { ExperienceConfig } from '../../utils/experience';
import type { PermissionGroups } from '../../utils/permission';
import { type SessionAction, SessionActionType } from './actions';
import { createStateMachineReducer } from './utils';

export enum LoginMode {
  Legacy = 'LEGACY',
  Hybrid = 'HYBRID',
  Modern = 'MODERN',
}

export enum SessionStateStatus {
  LoggedOut = 'LOGGED_OUT',
  LoggingIn = 'LOGGING_IN',
  NeedsMfaSetup = 'NEEDS_MFA_SETUP',
  SettingUpMfa = 'SETTING_UP_MFA',
  VerifyingMfa = 'VERIFYING_MFA',
  RestoringSession = 'RESTORING_SESSION',
  LoggedIn = 'LOGGED_IN',
  LoggingOut = 'LOGGING_OUT',
  Redirecting = 'REDIRECTING',
}

export type SessionState = TaggedUnion<
  { status: SessionStateStatus },
  {
    [SessionStateStatus.LoggedOut]: {
      lastError: Error | null;
    };
    [SessionStateStatus.LoggingIn]: {
      realm: string;
      username: string;
      password: string;
    };
    [SessionStateStatus.NeedsMfaSetup]: {
      realm: string;
      lastError: Error | null;
    };
    [SessionStateStatus.SettingUpMfa]: {
      realm: string;
      cellphone: string;
      countryCode: string;
    };
    [SessionStateStatus.VerifyingMfa]: {
      realm: string;
      qrCode: string | null;
      attempts: number;
    };
    [SessionStateStatus.RestoringSession]: {
      ignoreErrors: boolean;
      lastRealm: string | null;
    };
    [SessionStateStatus.LoggedIn]: {
      mode: LoginMode;
      user: CurrentUser;
      experienceConfig: ExperienceConfig | null;
      subdivisionStrategies: SubdivisionStrategies | null;
      permissionGroups: PermissionGroups | null;
      preferences: Preferences;
      settings: Settings;
    };
    [SessionStateStatus.LoggingOut]: {
      lastError: Error | null;
      lastRealm: string | null;
    };
    [SessionStateStatus.Redirecting]: {
      url: string;
    };
  }
>;

export default createStateMachineReducer<SessionState, SessionAction>({
  [SessionStateStatus.LoggedOut]: {
    [SessionActionType.Login](_state, { username, password, realm }) {
      return {
        status: SessionStateStatus.LoggingIn,
        username,
        password,
        realm,
      };
    },

    [SessionActionType.RestoreSession](_state, { ignoreErrors, lastRealm }) {
      return {
        status: SessionStateStatus.RestoringSession,
        lastRealm,
        ignoreErrors,
      };
    },

    [SessionActionType.RestoreSessionSucceeded](
      _state,
      {
        mode,
        user,
        experienceConfig,
        subdivisionStrategies,
        permissionGroups,
        preferences,
        settings,
      },
    ) {
      return {
        status: SessionStateStatus.LoggedIn,
        mode,
        user,
        experienceConfig,
        subdivisionStrategies,
        permissionGroups,
        preferences,
        settings,
      };
    },

    [SessionActionType.DismissError](state) {
      return {
        ...state,
        lastError: null,
      };
    },
  },

  [SessionStateStatus.LoggingIn]: {
    [SessionActionType.LoginSucceeded]({ realm }, { mfaAction }) {
      switch (mfaAction) {
        case null:
          return {
            status: SessionStateStatus.RestoringSession,
            lastRealm: realm,
            ignoreErrors: false,
          };

        case MfaAction.VerifyMfa:
          return {
            status: SessionStateStatus.VerifyingMfa,
            realm,
            qrCode: null,
            attempts: 0,
          };

        case MfaAction.SetupMfa:
          return {
            status: SessionStateStatus.NeedsMfaSetup,
            realm,
            lastError: null,
          };

        default:
          return {
            status: SessionStateStatus.LoggingOut,
            lastRealm: realm,
            lastError: new Error(`Unexpected MFA action "${mfaAction}"`),
          };
      }
    },

    [SessionActionType.LoginFailed]({ realm }, { error }) {
      return {
        status: SessionStateStatus.LoggingOut,
        lastRealm: realm,
        lastError: error,
      };
    },
  },

  [SessionStateStatus.NeedsMfaSetup]: {
    [SessionActionType.SetupMfa]({ realm }, { cellphone, countryCode }) {
      return {
        status: SessionStateStatus.SettingUpMfa,
        realm,
        cellphone,
        countryCode,
      };
    },

    [SessionActionType.DismissError](state) {
      return {
        ...state,
        lastError: null,
      };
    },

    [SessionActionType.Logout]({ realm }) {
      return {
        status: SessionStateStatus.LoggingOut,
        lastRealm: realm,
        lastError: null,
      };
    },
  },

  [SessionStateStatus.SettingUpMfa]: {
    [SessionActionType.SetupMfaSucceeded]({ realm }, { qrCode }) {
      return {
        status: SessionStateStatus.VerifyingMfa,
        realm,
        qrCode,
        attempts: 0,
      };
    },

    [SessionActionType.SetupMfaFailed]({ realm }, { error }) {
      return {
        status: SessionStateStatus.NeedsMfaSetup,
        realm,
        lastError: error,
      };
    },

    [SessionActionType.Logout]({ realm }) {
      return {
        status: SessionStateStatus.LoggingOut,
        lastRealm: realm,
        lastError: null,
      };
    },
  },

  [SessionStateStatus.VerifyingMfa]: {
    [SessionActionType.VerifyMfaSucceeded](
      { realm, qrCode, attempts },
      { mfaStatus },
    ) {
      switch (mfaStatus) {
        case MfaStatus.Approved:
          return {
            status: SessionStateStatus.RestoringSession,
            lastRealm: realm,
            ignoreErrors: false,
          };

        case MfaStatus.Pending:
          return {
            status: SessionStateStatus.VerifyingMfa,
            realm,
            qrCode,
            attempts: attempts + 1,
          };

        case MfaStatus.Denied:
          return {
            status: SessionStateStatus.LoggingOut,
            lastRealm: realm,
            lastError: new Error('MFA denied'),
          };

        case MfaStatus.Expired:
          return {
            status: SessionStateStatus.LoggingOut,
            lastRealm: realm,
            lastError: new Error('MFA expired'),
          };

        default:
          return {
            status: SessionStateStatus.LoggingOut,
            lastRealm: realm,
            lastError: new Error(`Unexpected MFA status "${mfaStatus}"`),
          };
      }
    },

    [SessionActionType.VerifyMfaFailed]({ realm }, { error }) {
      return {
        status: SessionStateStatus.LoggingOut,
        lastRealm: realm,
        lastError: error,
      };
    },

    [SessionActionType.Logout]({ realm }) {
      return {
        status: SessionStateStatus.LoggingOut,
        lastRealm: realm,
        lastError: null,
      };
    },
  },

  [SessionStateStatus.RestoringSession]: {
    [SessionActionType.RestoreSessionSucceeded](
      _state,
      {
        mode,
        user,
        experienceConfig,
        subdivisionStrategies,
        permissionGroups,
        preferences,
        settings,
      },
    ) {
      return {
        status: SessionStateStatus.LoggedIn,
        mode,
        user,
        experienceConfig,
        permissionGroups,
        subdivisionStrategies,
        preferences,
        settings,
      };
    },

    [SessionActionType.RestoreSessionFailed](
      { lastRealm, ignoreErrors },
      { error },
    ) {
      return {
        status: SessionStateStatus.LoggingOut,
        lastRealm,
        lastError: ignoreErrors ? null : error,
      };
    },

    [SessionActionType.Logout]({ lastRealm }) {
      return {
        status: SessionStateStatus.LoggingOut,
        lastRealm,
        lastError: null,
      };
    },
  },

  [SessionStateStatus.LoggedIn]: {
    [SessionActionType.Logout]({ user }) {
      return {
        status: SessionStateStatus.LoggingOut,
        lastRealm: user.realm,
        lastError: null,
      };
    },

    [SessionActionType.UpdatePreferences](state, { preferences }) {
      return {
        ...state,
        preferences: {
          ...state.preferences,
          ...preferences,
        },
      };
    },

    [SessionActionType.RestoreSession]({ user }, { ignoreErrors, lastRealm }) {
      return {
        status: SessionStateStatus.RestoringSession,
        lastRealm: lastRealm ?? user.realm,
        ignoreErrors,
      };
    },

    [SessionActionType.UpdateUser](state, { user }) {
      return { ...state, user };
    },
  },

  [SessionStateStatus.LoggingOut]: {
    [SessionActionType.LogoutFinished]({ lastError }, _action) {
      return {
        status: SessionStateStatus.LoggedOut,
        lastError,
      };
    },
  },
});
