import { useMemo } from 'react';

import type { AccessContext } from '@studio/api/access';
import { useRequestContext_UNSAFE } from '@studio/utils/requestContext';

import { useExperienceContext } from '../components/ExperienceManager';
import {
  useEnvironmentConfigSafely,
  useUser,
  type UseUserOptions,
} from '../components/SessionManager';
import { getExperienceAccessContext } from './experience';

export interface UseAccessContextOptions extends UseUserOptions {}

export default function useAccessContext({
  resolveExperienceRoles = true,
}: UseAccessContextOptions = {}): Partial<AccessContext> {
  const user = useUser({
    resolveExperienceRoles,
  });

  const envConfig = useEnvironmentConfigSafely();
  const { publishingSiteId } = useRequestContext_UNSAFE();

  const experience = useExperienceContext()?.experience;

  const env = envConfig?.type;
  const stackName = envConfig?.stack;
  const realm = user?.realm;
  const userRoles = user?.roles;
  const publishingSite = publishingSiteId ?? undefined;

  return useMemo(() => {
    const roles = new Set(userRoles);

    let accessContext: Partial<AccessContext> = {
      env,
      stackName,
      realm,
      roles,
      studioExperience: undefined,
      subdivisionMarket: undefined,
      subdivisionTenant: undefined,
      publishingSite,
    } satisfies {
      [K in keyof AccessContext]-?: AccessContext[K] | undefined;
    };

    if (resolveExperienceRoles && experience != null) {
      accessContext = getExperienceAccessContext(
        accessContext,
        experience,
        roles,
      );
    }

    return accessContext;
  }, [
    userRoles,
    env,
    stackName,
    realm,
    publishingSite,
    resolveExperienceRoles,
    experience,
  ]);
}
