import {
  type BoltEnv,
  parseEnvironmentConfigFromHostname,
} from '@studio/utils/fromEnvironment';

import { API_BASE_URL } from '../constants';

export type SubdivisionStrategy =
  | 'tenant'
  | 'market'
  | 'tenant-market'
  | 'global';

export interface SubdivisionStrategyOverride {
  readonly path: `/${string}`;
  readonly strategy: SubdivisionStrategy | null;
}

export type SubdivisionStrategies = readonly SubdivisionStrategyOverride[];

const ENVIRONMENT_CONFIG = parseEnvironmentConfigFromHostname();

function getSubdivisionHost(
  env: BoltEnv,
  subdivisionStrategy: SubdivisionStrategy,
  subdivisionTenant: string | null | undefined,
  subdivisionMarket: string | null | undefined,
): string {
  switch (subdivisionStrategy) {
    case 'global':
      return `studio.any-any.${env}.api.discomax.com`;

    case 'tenant':
      if (subdivisionTenant == null) {
        throw new TypeError('subdivisionTenant is required');
      }
      return `studio.${subdivisionTenant}-any.${env}.api.discomax.com`;

    case 'market':
      if (subdivisionMarket == null) {
        throw new TypeError('subdivisionMarket is required');
      }
      return `studio.any-${subdivisionMarket}.${env}.api.discomax.com`;

    case 'tenant-market':
      if (subdivisionTenant == null) {
        throw new TypeError('subdivisionTenant is required');
      }
      if (subdivisionMarket == null) {
        throw new TypeError('subdivisionMarket is required');
      }
      return `studio.${subdivisionTenant}-${subdivisionMarket}.${env}.api.discomax.com`;

    default:
      subdivisionStrategy satisfies never;
      throw new TypeError(
        `Invalid subdivision strategy: ${subdivisionStrategy}`,
      );
  }
}

export function getSubdivisionURL(
  url: URL,
  subdivisionStrategies: SubdivisionStrategies,
  subdivisionTenant: string | null | undefined,
  subdivisionMarket: string | null | undefined,
): URL {
  if (
    ENVIRONMENT_CONFIG?.source === 'bolt' &&
    url.href.startsWith(API_BASE_URL.href)
  ) {
    const path = API_BASE_URL.pathname.endsWith('/')
      ? url.pathname.slice(API_BASE_URL.pathname.length - 1)
      : url.pathname.slice(API_BASE_URL.pathname.length);

    for (const override of subdivisionStrategies) {
      if (path.startsWith(override.path)) {
        if (override.strategy !== null) {
          const subdivisionHost = getSubdivisionHost(
            ENVIRONMENT_CONFIG.env,
            override.strategy,
            subdivisionTenant,
            subdivisionMarket,
          );

          // We should probably create a new URL here...
          // protocol is always https
          url.protocol = 'https:';
          url.port = '';
          url.host = subdivisionHost;
        }

        break;
      }
    }
  }

  return url;
}
