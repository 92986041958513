import { memoizeWeakKey } from '@studio/utils/memoize';

import type { HybridHistory } from './hybridHistory';
import useHybridHistory from './useHybridHistory';
import { resolveLocation, type To } from './utils';

export interface HrefOptions {
  readonly pop?: boolean;
}

export type CreateHref = (to: To, options?: HrefOptions) => string;

const getCreateHref = memoizeWeakKey((history: HybridHistory): CreateHref => {
  return function createHref(to, options) {
    const nextLocation = resolveLocation(history.location, to);
    const pop = options?.pop ?? false;
    if (pop) {
      return history.createPopHref(nextLocation);
    } else {
      return history.createHref(nextLocation);
    }
  };
});

export default function useCreateHref(): CreateHref {
  const history = useHybridHistory();
  return getCreateHref(history);
}
