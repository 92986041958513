import type { Branded } from '@studio/utils/types';

import type { Experience } from './experience';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
declare const PERMISSION_GROUP_NAME_SYMBOL: unique symbol;
export type PermissionGroupName = Branded<
  string,
  typeof PERMISSION_GROUP_NAME_SYMBOL
>;

export interface PermissionGroupRole {
  readonly name: string;
  readonly conditions?: Partial<Experience>;
}

export interface PermissionGroupMetadata {
  readonly displayName: string;
  readonly description: string;
  readonly categories: readonly string[];
  readonly approverGroups: readonly string[];
}

export interface PermissionGroup {
  readonly name: PermissionGroupName;
  readonly conditions: Partial<Experience>;
  readonly meta: PermissionGroupMetadata;
  readonly roles: readonly PermissionGroupRole[];
}

export type PermissionGroups = ReadonlyMap<
  PermissionGroupName,
  PermissionGroup
>;

type Context<T> = {
  readonly [_ in Extract<keyof T, string>]?: string | undefined | null;
};

// A ⊆ B
export function isSubsetOfContext<
  const A extends Context<A>,
  const B extends Context<B>,
>(
  a: A | null | undefined,
  b: B | null | undefined,
  keysToCheck?: readonly (keyof A | keyof B)[] | null,
): boolean {
  if (a == null) {
    return true;
  }

  for (const key in a) {
    if (
      // A has the key
      Object.hasOwn(a, key) &&
      // the key is allowed
      (keysToCheck == null || keysToCheck.includes(key)) &&
      // A's value is not blank
      (a[key] ?? '') !== '' &&
      // B is null
      (b == null ||
        // ...or B doesn't have the the same key
        !Object.hasOwn(b, key) ||
        // ...or A's and B's values are not equal
        !Object.is(a[key], b[key]))
    ) {
      // A is not a subset of B
      return false;
    }
  }

  return true;
}

export function isEqualContext<
  const A extends Context<A>,
  const B extends Context<B>,
>(
  contextA: A | null | undefined,
  contextB: B | null | undefined,
  keysToCheck?: readonly (keyof A | keyof B)[] | null,
): boolean {
  return (
    isSubsetOfContext(contextA, contextB, keysToCheck) &&
    isSubsetOfContext(contextB, contextA, keysToCheck)
  );
}
