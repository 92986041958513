import { useEffect } from 'react';

import {
  type RequestContext,
  useRequestContext_UNSAFE,
} from './requestContext';
import useEventCallback from './useEventCallback';
import useQuery, {
  type AnyRequest,
  getQueryKey,
  type QueryKey,
  type RequestData,
  type RequestParams,
  type Updater,
} from './useQuery';
import useQueryCache from './useQueryCache';

export {
  /** @deprecated Prefer QueryCache */
  type QueryCache as RequestCache,
  /** @deprecated Prefer {@see QueryCacheProvider} */
  QueryCacheProvider as RequestCacheProvider,
  /** @deprecated Prefer QueryCacheProviderProps */
  type QueryCacheProviderProps as RequestCacheProviderProps,
  /** @deprecated Prefer useQueryCache */
  default as useRequestCache,
} from './useQueryCache';
export { ReactQueryDevtools as Devtools } from '@tanstack/react-query-devtools';
export { getQueryKey, type QueryKey, type Updater };

export interface CachedAutoRequestConfig {
  /** If skip is true, the request will be skipped entirely */
  readonly skip?: boolean;
  /** Specifies the interval in ms at which you want your component to poll for data */
  readonly poll?: boolean | number;
  /** If the previous data should be kept while loading the next data */
  readonly keepDataWhileLoading?: boolean;
  /** If the previous data should be kept while skipping */
  readonly keepDataWhileSkipping?: boolean;
  /** The time in ms that cache data remains fresh (default 0ms) */
  readonly staleTime?: number;
  /** The time in ms that unused/inactive cache data remains in memory (default 5m) */
  readonly cacheTime?: number;
  /** Override request context */
  readonly context?: RequestContext;
}

interface CachedAutoRequestConfigWithParams<in Request extends AnyRequest>
  extends CachedAutoRequestConfig {
  /** Function parameters to inject. Setting this to null will skip the call. */
  readonly params: RequestParams<Request> | null;
}

type PartialPartial<T, K extends keyof T> = Omit<T, K> & Pick<Partial<T>, K>;

type CachedAutoRequestConfigWithOptionalParams<Request extends AnyRequest> =
  // Make the params optional if [] is assignable to it
  readonly [] extends RequestParams<Request>
    ? PartialPartial<CachedAutoRequestConfigWithParams<Request>, 'params'>
    : CachedAutoRequestConfigWithParams<Request>;

interface ManualErrorHandlingConfig {
  /** If errors should be handled manually */
  readonly handleErrorManually: boolean;
  /** @deprecated Please don't use this unless absolutely necessary */
}

export interface CachedAutoRequest<in out Request extends AnyRequest> {
  readonly data: RequestData<Request> | undefined;
  /** Indicates when initial data is being fetched */
  readonly loading: boolean;
  /** Always indicates when data is being fetched */
  readonly fetching: boolean;
  readonly setData: (updater: Updater<RequestData<Request>>) => void;
  readonly refresh: () => void;
  readonly queryKey: QueryKey<Request> | undefined;
}

export interface CachedAutoRequestWithError<in out Request extends AnyRequest>
  extends CachedAutoRequest<Request> {
  readonly error: Error | undefined;
}

/**
 * A hook to manage the side effects of a request, including errors.
 * It refetches whenever the request function changes, so make sure to use a stable function in combination with the the `params` parameter.
 * @example
 * const { data: video, loading, error } = useCachedAutoRequest(
 *   vmsApi.getVideo,
 *   {
 *     params: [videoId],
 *     handleErrorManually: true,
 *   }
 * );
 */
export default function useCachedAutoRequest<const Request extends AnyRequest>(
  request: Request,
  config: CachedAutoRequestConfigWithOptionalParams<Request> &
    ManualErrorHandlingConfig,
): CachedAutoRequestWithError<Request>;

/**
 * A hook to manage the side effects of a request.
 * It refetches whenever the request function changes, so make sure to use a stable function in combination with the the `params` parameter.
 * @example
 * const { data: video, loading } = useCachedAutoRequest(
 *   vmsApi.getVideo,
 *   {
 *     params: [videoId],
 *   }
 * );
 */
export default function useCachedAutoRequest<const Request extends AnyRequest>(
  request: Request,
  config?: CachedAutoRequestConfigWithOptionalParams<Request>,
): CachedAutoRequest<Request>;

export default function useCachedAutoRequest<const Request extends AnyRequest>(
  request: Request,
  {
    skip = false,
    poll,
    keepDataWhileLoading = false,
    keepDataWhileSkipping = false,
    handleErrorManually = false,
    staleTime = 1 * 60 * 1000,
    cacheTime = 60 * 60 * 1000,
    params = [] as any,
    context: contextOverride,
  }: Partial<
    CachedAutoRequestConfigWithParams<Request> & ManualErrorHandlingConfig
  > = {},
): CachedAutoRequestWithError<Request> {
  skip ||= params === null;

  const incomingContext = useRequestContext_UNSAFE();

  const context = contextOverride ?? incomingContext;

  const queryKey =
    params === null ? undefined : getQueryKey(request, context, ...params);

  const { data, error, refetch, isLoading, isFetching } = useQuery({
    queryKey,
    skip,
    poll,
    keepDataWhileLoading,
    keepDataWhileSkipping,
    handleErrorManually,
    staleTime,
    cacheTime,
  });

  const queryCache = useQueryCache();

  const setData = useEventCallback<CachedAutoRequest<Request>['setData']>(
    updater => {
      if (queryKey !== undefined) {
        queryCache.setQueryData(queryKey, updater);
      }
    },
  );

  let didHandleError = false;

  useEffect(() => {
    // Report unhandled errors
    if (handleErrorManually && !didHandleError && error != null) {
      console.warn(
        new Error(
          '🚨 Unhandled error in useCachedAutoRequest! 🚨 Please set handleErrorManually to false, or use the returned error!',
          { cause: error },
        ),
      );
    }
  });

  return {
    data,
    get error() {
      didHandleError = true;
      return error ?? undefined;
    },
    loading: !skip && isLoading,
    fetching: !skip && isFetching,
    refresh: refetch,
    setData,
    queryKey,
  } as const;
}
