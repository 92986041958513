import type { RequestContext } from '@studio/utils/requestContext';

import type {
  SecondaryExperienceKey,
  VerifiedExperience,
} from '../../utils/experience';

export function getNextRequestContext(
  prevRequestContext: RequestContext,
  verifiedExperience: VerifiedExperience | null,
): RequestContext {
  return {
    ...prevRequestContext,
    experience: verifiedExperience,
    publishingSiteId: verifiedExperience?.publishingSite ?? null,
  };
}

const EXPERIENCE_KEY_PRIORITY: Readonly<
  Record<SecondaryExperienceKey, number>
> = {
  publishingSite: 0,
  productLine: 1,
  commerceLine: 2,
  subdivisionMarket: 3,
  subdivisionTenant: 4,
};

Object.setPrototypeOf(EXPERIENCE_KEY_PRIORITY, null);

export function secondaryExperienceKeyComparator(
  keyA: SecondaryExperienceKey,
  keyB: SecondaryExperienceKey,
): number {
  const priorityA = EXPERIENCE_KEY_PRIORITY[keyA] ?? Infinity;
  const priorityB = EXPERIENCE_KEY_PRIORITY[keyB] ?? Infinity;
  return priorityA - priorityB;
}
