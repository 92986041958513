import {
  emphasize,
  getContrastRatio,
  StyledEngineProvider,
  type SxProps,
  type Theme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import type { SystemStyleObject } from '@mui/system';
import {
  createContext,
  type ReactElement,
  type ReactNode,
  useContext,
} from 'react';

import isReadonlyArray from '@studio/utils/isReadonlyArray';

export type { Breakpoint } from '@mui/material';
export * from '@mui/material/styles';

export type ThemeOverride = Partial<Theme> | ((outerTheme: Theme) => Theme);
export type ThemeProviderProps = {
  theme: ThemeOverride;
  children: ReactNode;
};

export function flattenSx<Theme extends object>(
  sx: SxProps<Theme>,
): (theme: Theme) => SystemStyleObject<Theme> {
  if (typeof sx === 'function') {
    return sx;
  }

  if (!isReadonlyArray(sx)) {
    return () => sx;
  }

  return theme => {
    const flattenedSx: SystemStyleObject<Theme> = {};

    for (const sxItem of sx) {
      if (typeof sxItem === 'function') {
        Object.assign(flattenedSx, sxItem(theme));
      } else if (typeof sxItem === 'object' && sxItem !== null) {
        Object.assign(flattenedSx, sxItem);
      }
    }

    return flattenedSx;
  };
}

function flattenSxProp(sxProp: SxProps<Theme> | undefined | false) {
  if (isReadonlyArray(sxProp)) {
    return sxProp;
  } else if (sxProp != null && sxProp !== false) {
    return [sxProp];
  } else {
    return [];
  }
}

export function mergeSx(
  ...sxProps: readonly (SxProps<Theme> | undefined | false)[]
): SxProps<Theme> {
  return sxProps.flatMap(flattenSxProp);
}

export type StylesType<StyleName extends string> = Readonly<
  Record<
    StyleName,
    SystemStyleObject<Theme> | ((theme: Theme) => SystemStyleObject<Theme>)
  >
>;

// Used to define a top-level psuedo-stylesheet
export function defineSx<StyleName extends string>(
  styles: StylesType<StyleName>,
): typeof styles {
  return styles;
}

export type ShouldForwardProp = (prop: PropertyKey) => boolean;

export const defaultShouldForwardProp: ShouldForwardProp = prop => {
  return !(
    prop === 'ownerState' ||
    prop === 'theme' ||
    prop === 'sx' ||
    prop === 'as'
  );
};

export function createShouldForwardProp<Props extends object>(forwardedProps: {
  readonly [K in keyof Props]-?: boolean;
}): ShouldForwardProp {
  const forwardedProps2: { [prop: PropertyKey]: boolean } = forwardedProps;
  return prop => {
    return defaultShouldForwardProp(prop) && forwardedProps2[prop] !== false;
  };
}

const ThemeOverrideContext = createContext<ThemeOverride>({});

const DEFAULT_THEME_OVERRIDE = {} as const;

export interface ThemeOverrideProviderProps {
  themeOverride: ThemeOverride | undefined;
  children: ReactNode;
}

export function ThemeOverrideProvider({
  themeOverride = DEFAULT_THEME_OVERRIDE,
  children,
}: ThemeOverrideProviderProps): ReactElement {
  return (
    <ThemeOverrideContext.Provider value={themeOverride}>
      {children}
    </ThemeOverrideContext.Provider>
  );
}

export function ThemeProvider({
  theme,
  children,
}: ThemeProviderProps): ReactElement {
  const themeOverride = useContext(ThemeOverrideContext);

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <MuiThemeProvider theme={themeOverride}>{children}</MuiThemeProvider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
}

export function getContrastTextColor(
  backgroundColor: string,
  prevForegroundColor: string,
  contrastThreshold: number,
): string {
  if (
    getContrastRatio(prevForegroundColor, backgroundColor) >= contrastThreshold
  ) {
    // Contrast is already good enough
    return prevForegroundColor;
  }

  // Binary search for a coefficient between 0 and 1 that gives contrast just above the threshold.
  let min = 0;
  let max = 1;
  let bestForegroundColor = prevForegroundColor;

  // Keep looping until min and max is almost the same value.
  while (Math.abs(max - min) > 0.01) {
    // Try the mid value and check its contrast with the background
    const mid = (max + min) / 2;
    const candidateColor = emphasize(prevForegroundColor, mid);
    const contrastRatio = getContrastRatio(candidateColor, backgroundColor);

    if (contrastRatio < contrastThreshold) {
      // Too low contrast. Try higher.
      min = mid;
    } else {
      // Good contrast. Save it, but see if we can get away with lower.
      bestForegroundColor = candidateColor;
      max = mid;
    }
  }
  return bestForegroundColor;
}
