import WeakValueMap from './WeakValueMap';

export function memoizeWeakKey<K extends WeakKey, V, This = unknown>(
  fn: (this: This, key: K) => V,
): {
  (this: This, key: K): V;
  get cache(): WeakMap<K, V>;
} {
  const cache = new WeakMap<K, V>();
  function memoized(this: This, key: K): V {
    if (!cache.has(key)) {
      const value = fn.call(this, key);
      cache.set(key, value);
      return value;
    }
    return cache.get(key)!;
  }
  memoized.cache = cache;
  return memoized;
}

export function memoizeWeakValue<K, V extends WeakKey, This = unknown>(
  fn: (this: This, key: K) => V,
): {
  (this: This, key: K): V;
  get cache(): WeakValueMap<K, V>;
} {
  const cache = new WeakValueMap<K, V>();
  function memoized(this: This, key: K): V {
    let value = cache.get(key);
    if (value === undefined) {
      value = fn.call(this, key);
      cache.set(key, value);
    }
    return value;
  }
  memoized.cache = cache;
  return memoized;
}
