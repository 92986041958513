import { useState } from 'react';

import deepEqual from './deepEqual';

export default function useLastEqualValue<T>(
  value: T,
  comparator: (a: T, b: T) => boolean = deepEqual,
): T {
  const [prevValue, setPrevValue] = useState(value);

  if (!comparator(prevValue, value)) {
    setPrevValue(value);
    return value;
  }

  return prevValue;
}
