import keys from './keys';

export type Labels<K extends string, V extends string = string> = {
  get items(): readonly K[];
  getItemKey(key: K): K;
  getItemLabel(key: K): V;
  getItemLabel(key: string): V | K;
};

export default function createLabels<
  K extends string,
  V extends string = string,
>(labels: Readonly<Record<K, V>>): Labels<K, V> {
  labels = Object.assign(Object.create(null), labels);
  const items = keys(labels);
  return {
    get items() {
      return items;
    },
    getItemKey(key: K): K {
      return key;
    },
    getItemLabel(key: K): V {
      return labels[key] ?? key;
    },
  };
}
