import { memo, type ReactElement } from 'react';

import { toCSS } from '@studio/utils/styles';

import Box from '../Box';
import CircularProgress, {
  type CircularProgressProps,
} from '../CircularProgress';
import Fade from '../Fade';
import { defineSx, mergeSx } from '../styles';

const styles = defineSx({
  outer: {
    position: 'relative',
    display: 'inline-block',
    contentVisibility: 'auto',
    zIndex: 100000,
  },
  inner: {
    position: 'absolute',
    display: 'block',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
});

export interface SpinnerProps extends CircularProgressProps {
  /** alias for {size} */
  width?: number;
  show?: boolean;
  /** alias for {centered} */
  overlay?: boolean;
  centered?: boolean;
  /** The time in ms to wait before showing the spinner */
  delay?: number;
}

function Spinner({
  show = true,
  width = 40,
  size = width,
  overlay = true,
  centered = overlay,
  // Material UI recommends delaying the appearance by 1s
  // https://material-ui.com/components/progress/#delaying-appearance
  delay = 1000,
  sx,
  ...props
}: SpinnerProps): ReactElement {
  const cssSize = toCSS(size);

  return (
    <Fade
      in={show}
      mountOnEnter
      unmountOnExit
      style={{
        transitionDelay: `${show ? delay : 0}ms`,
      }}
    >
      <Box
        component="span"
        sx={mergeSx(
          styles.outer,
          {
            width: cssSize,
            height: cssSize,
          },
          centered && {
            pointerEvents: 'none',
            position: 'absolute',
            left: `calc(50% - calc(${cssSize} / 2))`,
            top: `calc(50% - calc(${cssSize} / 2))`,
          },
          sx,
        )}
      >
        <CircularProgress
          variant="indeterminate"
          disableShrink
          data-testid="loading-spinner"
          {...props}
          size={size}
          sx={styles.inner}
          aria-label="loading-spinner"
          role="alert"
          aria-busy="true"
        />
      </Box>
    </Fade>
  );
}

export default memo(Spinner);
