import type { StudioExperience } from '@studio/core-common/utils/experience';
import type { EnvironmentType } from '@studio/utils/fromEnvironment';

import type { Role } from './roles';
import type { Evaluator } from './RuleEngine';

type StringRole = `${Role}`;

export interface AccessContext {
  readonly env: EnvironmentType;
  readonly realm: string;
  readonly roles: ReadonlySet<StringRole>;
  readonly publishingSite: string;
  readonly stackName: string;
  /** @deprecated */
  readonly subdivisionMarket: string;
  /** @deprecated */
  readonly subdivisionTenant: string;
  readonly studioExperience: StudioExperience | null;
}

export type AccessFn = Evaluator<AccessContext>;

function checkAccess(this: AccessContext, fn: AccessFn): boolean {
  return fn(this);
}

export function and(...fns: readonly AccessFn[]): AccessFn {
  return ctx => fns.every(checkAccess, ctx);
}

export function or(...fns: readonly AccessFn[]): AccessFn {
  return ctx => fns.some(checkAccess, ctx);
}

export function not(fn: AccessFn): AccessFn {
  return ctx => !fn(ctx);
}
