import { unstable_batchedUpdates as batchedUpdates } from 'react-dom';

export type Listener = () => void;
export type Unsubscribe = () => void;

export interface Notifier {
  listen: (listener: Listener) => Unsubscribe;
  notify: () => void;
}

export function createNotifier(): Notifier {
  const listeners = new Set<Listener>();

  return {
    listen(listener: Listener): Unsubscribe {
      listeners.add(listener);

      return () => {
        listeners.delete(listener);
      };
    },

    notify: batchedUpdates.bind(undefined, () => {
      for (const listener of listeners) {
        try {
          listener();
        } catch (error) {
          // This is a global function to report an error without failing it
          // https://developer.mozilla.org/en-US/docs/Web/API/Window/reportError
          reportError(error);
        }
      }
    }),
  };
}
