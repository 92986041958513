import createReadonlySet from '@studio/utils/createReadonlySet';

import type { ContentPromotionFeature } from './content-promotion/contentPromotionfeatures';
import type { CurationPromotionFeature } from './curation-promotion/curationPromotionFeatures';
import { SonicFeature } from './features';
import RuleEngine, { type Evaluator } from './RuleEngine';
import rules from './rules';
import { type AccessContext, type AccessFn, and } from './utils';

export * from './content-promotion/contentPromotionfeatures';
export * from './curation-promotion/curationPromotionFeatures';
export * from './features';
export * from './roles';
export * from './utils';

export const ruleEngine = new RuleEngine<AccessContext>(rules);

export const ruleEvaluators = Object.fromEntries(
  ruleEngine.getRuleNames().map(ruleName => {
    const rule = ruleEngine.getRuleOrThrow(ruleName);
    const evaluate = ruleEngine.createEvaluator(rule);
    return [
      ruleName,
      // Wraps it with a dynamically named function
      { [ruleName]: (ctx: Partial<AccessContext>) => evaluate(ctx) }[ruleName]!,
    ];
  }),
) as Readonly<Record<keyof typeof rules, Evaluator<AccessContext>>>;

const CUSTOMER_SUPPORT_EDITABLE_FIELDS = createReadonlySet([
  'username',
  'deleted',
  // 'firstName', // <-- editable in backend (needed for VIP create), but keep readonly in user view
  // 'lastName',  // <-- editable in backend (needed for VIP create), but keep readonly in user view
]);

const USERS_ADMIN_DENIED_FIELDS = createReadonlySet(['roles', 'mfaEnabled']);

export function canEditUserAttribute(attr: string): AccessFn {
  const { isSuperAdmin, isUsersAdmin, isCustomerSupport } = ruleEvaluators;
  return ctx =>
    isSuperAdmin(ctx) ||
    (isUsersAdmin(ctx) && !USERS_ADMIN_DENIED_FIELDS.has(attr)) ||
    (isCustomerSupport(ctx) && CUSTOMER_SUPPORT_EDITABLE_FIELDS.has(attr));
}

export type SonicAppFeatures =
  | SonicFeature
  | ContentPromotionFeature
  | CurationPromotionFeature;

export function isFeatureEnabled(feature: SonicAppFeatures): AccessFn {
  return ruleEvaluators[feature];
}

/** @deprecated DO NOT USE THIS */
export const usesAllPromotedFeatures = and(
  isFeatureEnabled(SonicFeature.IMSForContentRatings),
  isFeatureEnabled(SonicFeature.IMSForContentDescriptors),
  isFeatureEnabled(SonicFeature.IMSForGenres),
  isFeatureEnabled(SonicFeature.IMSForTaxonomyNodes),
  isFeatureEnabled(SonicFeature.ListableAndPlayableTerritories),
  isFeatureEnabled(SonicFeature.Localizations),
  isFeatureEnabled(SonicFeature.SourceSystemId),
  isFeatureEnabled(SonicFeature.Taxonomies),
);

export const {
  isSuperAdmin,
  isEditor,
  isUsersAdmin,
  isTestSubscriptionsManager,
  isCustomerSupport,
  isTaxonomyManager,
  isSonicStudioBetaTester,
  isSonicDeveloper,
  isCapabilityManager,
  isIpWhitelister,
  isGeoOverrider,
  isCampaignManager,
  isProductManager,
  isLabsManager,
  isLabsViewer,
  isLabsAdmin,
  isLegalManager,
  isTemplateManager,
  isRARApprover,
  isAccountingEditor,
  isContractManager,
  isBusinessReader,
  isEditorialReader,
  isContentPromoter,
  isContentPromoterReader,
  isMerchandiser,
  isMerchandisingReader,
  isProductLineEditor,
  isSiteBuilderManager,
  isSiteConfigManager,
  isFullStoryEnabled,
  isContentInsightEnabled,
  isContentVisualizationEnabled,
  isGeneralRightsEnabled,
  isGenresEnabled,
  isIMSForContentRatingsEnabled,
  isIMSForContentDescriptorsEnabled,
  isIMSForGenresEnabled,
  isIMSForLivestreamsEnabled,
  isIMSForTaxonomyNodesEnabled,
  isIMSForVideoAssetsEnabled,
  isListableAndPlayableAppNamesEnabled,
  isListableAndPlayableTerritoriesEnabled,
  isLiveVideosEnabled,
  isLocalizationsEnabled,
  isLookerLinksEnabled,
  isMediaDownloadEnabled,
  isOffersEnabled,
  isOffersReadOnlyEnabled,
  isOTPEnabled,
  isPersonalizedAIRecommendationsEnabled,
  isHopperRecommendationsEnabled,
  isProvidersEnabled,
  isRelatedSiteBuilderObjectsEnabled,
  isSourceSystemIdEnabled,
  isSubtitlesEnabled,
  isTagsEnabled,
  isTaxonomiesEnabled,
  isTVListingsEnabled,
  isVideoAssetUploadEnabled,
  isVMSEnabled,
  isMainTerritoryCodeAndBrandIdOnTermKindsEnabled,
  isContentProtectionEnabled,
  isDTCRARContractsEnabled,
  isDTCRARProductsEnabled,
  isPricePlanProvidersWhenOngoingEnabled,
  isMergedCollectionsEnabled,
  isSubscriptionJourneyEnabled,
  isModelComparisonEnabled,
  isCompassRerankingEnabled,
  isPricePlanFilteringByBrandEnabled,
  isAudienceTargetingEnabled,
  isGauthPartnersEnabled,
  isSiteBuilderTemplatesEnabled,
  isDetokenizePaymentMethodEnabled,
  isPayPerViewLegacyEnabled,
  isPayPerViewBoltEnabled,
  isTerminateReasonNorwayDenmarkEnabled,
  isPackagesInCapabilitiesEnabled,
  isAddonProductsEnabled,
  canAccessContentInsight,
  canAccessContentVisualization,
  canPlayContent,
  canAccessVideos,
  canManageVideos,
  canAccessImageRelationships,
  canManageImageRelationships,
  canAccessSiteBuilder,
  canAccessSiteBuilderBolt,
  canAccessSiteBuilderLegacy,
  canEditPermissions,
  canManageSiteBuilder,
  canEditSiteBuilderConfigs,
  canViewSiteBuilderConfigs,
  canUseBasicAirings,
  canUseCompleteAirings,
  canEditSiteBuilderFeatures,
  canManageSiteBuilderExperiments,
  canUseStudioGlobalExpansionFeatures,
  canUseRolesPermissions,
  canEditSiteBuilderTemplates,
  canManageMonetization,
  canManagePricePlans,
  canAccessPricePlans,
  canAccessPlans,
  canManagePlans,
  canManageProducts,
  canAccessProducts,
  canAccessSAPMasterData,
  canManageProductTransitions,
  canAccessProductTransitions,
  canManagePackages,
  canAccessPackages,
  canManageFeatures,
  canAccessFeatures,
  canManageCampaigns,
  canAccessCampaigns,
  canManageAffiliates,
  canAccessAffiliates,
  canManageContracts,
  canAccessContracts,
  canAccessContractsFromProducts,
  canAccessMonetization,
  canAccessMonetizationBolt,
  canAccessMonetizationLegacy,
  canAccessUsers,
  canCreateUser,
  canViewUsers,
  canSearchUsers,
  canDeleteUsers,
  canUpdateUsers,
  hasBlockedEmailPermission,
  hasWhitelistPermission,
  hasGeoOverridePermission,
  canManageTestSubscriptions,
  canDisableMfa,
  canManageUserSubscriptions,
  canAccessUserRoles,
  canAccessUserCapabilities,
  canAccessUserCapabilitiesV1,
  canAccessUserCapabilitiesV2,
  canSearchAudit,
  canManageFaultyTransactions,
  canAccessCommunicationHistory,
  canManageOffers,
  canAccessOffers,
  canAccessUserIdentity,
  canManageLabsLimited,
  canManageLabsManager,
  canManageLabsFull,
  canAccessLabs,
  canAccessLabsBolt,
  canAccessLabsLegacy,
  canViewLabsData,
  canAccessRatings,
  canManageRatings,
  canAccessTaxonomy,
  canManageTaxonomy,
  canAccessTaxonomyRelationships,
  canEditTaxonomyRelationships,
  canAccessLegal,
  canManageCommunications,
  canAccessCommunications,
  canAccessCapabilities,
  canAccessCapabilitiesBolt,
  canAccessCapabilitiesLegacy,
  canManageCapabilities,
  canAccessPPVEntitlements,
  canManagePPVEntitlements,
  canAccessSettings,
  canLocalizeContentRestrictions,
  canAccessSiteSetupWizard,
  canAccessSearch,
  canAccessImages,
  canAccessSonicStudioSandbox,
  canManageSubscriptionJourney,
  canAccessSubscriptionJourney,
  canManageSubscriptionJourneyExperiments,
  canManageMarketingInfo,
  canAccessMarketingInfo,
  canAccessProductVariantsMarketing,
  canAccessUserAddonSubscriptions,
  canAccessAudits,
  canAccessModelComparison,
  canAccessAvatars,
  canAccessPayPerView,
  canManagePayPerView,
  canAccessPayPerViewBolt,
  canManagePayPerViewBolt,
  canDoLogoutEverywhere,
  canToggleReranking,
  canAccessComparisonShowTypeFilter,
  canAccessHorizontalReranking,
  canAccessCollectionPersonalization,
  canAccessLegacySearch,
  canAccessUserConsentDetails,
  canAccessVerticalReranking,
  isHotjarEnabled,
  isPostHogEnabled,
  canAccessGSM,
  canEditGSM,
  canAccessCollaborators,
  canCreateOrEditRoutes,
  canAccessMultiverse,
  canAccessMultiverseReleaseEnroll,
  isMultiverse,
  canDownloadStudioUserData,
  canAccessLocalizationReporting,
  canAccessLocalizationBulkUpload,
  canAccessStudioAccess,
  canToggleWholePageOptimization,
  canAccessUserDeviceInfo,
  canAccessTerritoryHierarchyDefinition,
  canAccessDiana,
  canAccessDianaIpRules,
  canViewDianaIpRules,
  canCreateDianaIpRule,
  canAccessDianaIpCategories,
  canViewStudioRolesWarning,
  canAccessPageGraph,
  canAccessUserDownloadsInfo,
  canReportSubscriptionJourney,
  canReportMonetization,
  isRolesPermissionsEnabled,
  canUseMVPDDefederation,
  canAccessBadges,
  canManageBadgeLocalizations,
  canAccessSegments,
  canUpdateUserMigration,
  isBoltLocalizationEnabled,
  canAccessPartnerMapping,
  canManagePartnerMapping,
  isStudioIdentityEnabled,
  canUpdateSilhouetteProfile,
  canAccessMultipass,
  canAccessProfileSimulator,
  canViewAuthProvidersInfo,
  canAccessElvisLegalFeatureFlag,
  canAccessFlash,
  canAccess$AppName,
  canAccessNewLocalizationHelperText,
  canViewAuthProvidersVerificationStatus,
  canEditMosaicFeatures,
  canViewMosaicPages,
  canAccessMosaic,
  canAccessSiteReporting,
  isProductLineFilterForTaxonomyNodeImgEnabled,
  canAccessBolt,
  canAccessMemberHub,

  /* Content Promotion */
  isContentPromotionEnabled,
  isContentPromotionHopperEnabled,
  isContentPromotionCampaignsEnabled,
  canUseGlobalHopper,
  canAccessContentPromotion,
  canAccessContentPromotionDplusApi,
  canAccessImmersiveHero,
  canAccessDistributionChannelCandidates,
  canAccessTaxonomyLinkCandidates,
  canManageHopperSettings,

  /* /Content Promotion */

  /* Curation Promotion */
  isCurationPromotionEnabled,
  isCurationPromotionSettingsEnabled,
  canAccessCurationPromotion,
  canAccessCurationPromotionSettings,

  /* /Curation Promotion */

  canAccessPlanBulkActions,
  canAccessMulticonfig,
  canViewNewNavigation,
  canAccessPartnerPortal,
  canAccessNewLocalizationWidget,
  // 🚨 DON'T REMOVE COMMENT : Export your access above
} = ruleEvaluators;
