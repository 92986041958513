import createUUID from '@studio/utils/createUUID';
import tryParseInt from '@studio/utils/tryParseInt';
import { OS_NAME, OS_VERSION } from '@studio/utils/userAgent';

export const enum HeaderName {
  Authorization = 'Authorization',
  Accept = 'Accept',
  ContentType = 'Content-Type',
  RetryAfter = 'Retry-After',
  DeviceInfo = 'X-Device-Info',
  DiscoClient = 'X-Disco-Client',
  SonicStudioParams = 'X-Sonic-Studio-Params',
  ProductLineID = 'X-WBD-Product-Line-ID',
  PublishingSiteID = 'X-WBD-Publishing-Site-ID',
  StudioIdentityExperiences = 'X-WBD-Studio-Identity-Experiences',
  /** We need to use this custom header instead of the standard Authorization */
  StudioIdentityToken = 'X-WBD-Studio-Identity-Token',
}

export enum MimeType {
  Wildcard = '*/*',
  JSON = 'application/json;charset=UTF-8',
  Text = 'text/plain;charset=UTF-8',
  JSONOrWildcard = `${MimeType.JSON}, ${MimeType.Wildcard}`,
}

export const DEVICE_ID = (function getDeviceId() {
  const DEVICE_ID_KEY = 'deviceId';
  let deviceId = localStorage.getItem(DEVICE_ID_KEY);
  if (deviceId === null) {
    deviceId = createUUID();
    localStorage.setItem(DEVICE_ID_KEY, deviceId);
  }
  return deviceId;
})();

export const BASE_URL = new URL('/', window.location.origin);
export const API_BASE_URL = new URL('/api/', window.location.origin);

export const BUILD_VERSION = process.env.REACT_APP_BUILD_VERSION ?? 'DEV';
export const CLIENT_ID = '5f3a4b2eefbc36f0c17b';
export const CLIENT_NAME = `WEB:UNKNOWN:SONIC_STUDIO:${BUILD_VERSION}`;
export const DEVICE_INFO = `Studio/${BUILD_VERSION} (desktop/desktop; ${OS_NAME}/${OS_VERSION}; ${DEVICE_ID}/${CLIENT_ID})`;

export const SEARCH_DEFAULT_PAGE = 0;
export const SEARCH_DEFAULT_PAGE_SIZE = 30;

export const SENTRY_DSN = process.env.SENTRY_DSN;
export const SENTRY_ORG = process.env.SENTRY_ORG;
export const SENTRY_PROJECT = process.env.SENTRY_PROJECT;

export const HOTJAR_SITE_ID = tryParseInt(process.env.HOTJAR_SITE_ID);

export const POSTHOG_PUBLIC_KEY = process.env.POSTHOG_PUBLIC_KEY;

export const GI_SLACK_URL =
  'https://wbdstreaming.slack.com/archives/C04FYTFGJGY';

export const STUDIO_SUPPORT_SLACK_URL =
  'https://wbdstreaming.slack.com/archives/C049R4RR9U7';

export const COMMERCE_SLACK_URL =
  'https://wbdstreaming.slack.com/archives/C04EEL3MS0G';

export const LABS_SLACK_URL =
  'https://wbdstreaming.slack.com/archives/C04F8THMJAX';

export const REQUEST_USER_ACCESS_URL =
  'https://wbd.service-now.com/serviceportal?id=sc_cat_item&sys_id=e46e1a4d1b6df5d06bf5535b234bcb76';

export const REQUEST_FEATURE_URL = 'https://forms.office.com/r/SBL2DjehFC';

export const REPORT_BUG_URL =
  'https://forms.office.com/Pages/ResponsePage.aspx?id=JYi0DnHoWUS8ctDs1o8fOT9UqW7q-79NkGeCMHe56n9URFNZUFlSMzVaT0pZRk5OM0lDMjEwRDdKOC4u';

export const REPORT_CONTENT_ISSUE_URL =
  'https://warnermedia.enterprise.slack.com/archives/C04AD5G4TJA';
