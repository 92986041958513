import type { Location } from 'history';
import { type Dispatch, useLayoutEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router';

import type { Mutable } from '@studio/utils/types';
import useDebouncedUpdate from '@studio/utils/useDebouncedUpdate';
import useEventCallback from '@studio/utils/useEventCallback';

import useNavigate from './useNavigate';

type StateShape<State> = {
  readonly [_ in Extract<keyof State, string>]?: unknown;
};

type RequiredFormattedState<UrlKeys extends string> = {
  readonly [_ in UrlKeys]: string | undefined;
};

type FormattedState<UrlKeys extends string> = {
  readonly [_ in UrlKeys]?: string;
};

export type SearchParamsParser<
  State extends StateShape<State>,
  UrlKeys extends string = Extract<keyof State, string>,
> = (formattedState: FormattedState<UrlKeys>) => State;

export type SearchParamsFormatter<
  State extends StateShape<State>,
  UrlKeys extends string = Extract<keyof State, string>,
> = (state: State) => RequiredFormattedState<UrlKeys>;

export type SearchAction<T> =
  | Partial<T>
  | ((prevState: T, defaultState: T) => Partial<T>);

function getSearch(location: Location): string {
  return location.search[0] === '?'
    ? location.search.slice(1)
    : location.search;
}

function parseSearch<UrlKeys extends string>(
  searchString: string,
): FormattedState<UrlKeys> {
  const formattedState = Object.fromEntries(new URLSearchParams(searchString));
  return formattedState as FormattedState<UrlKeys>;
}

// Creates a "short" search string, omitting default values
function createShortSearch<UrlKeys extends string>(
  formattedState: FormattedState<UrlKeys>,
  formattedDefaultState: FormattedState<UrlKeys>,
  unknownState: FormattedState<string>,
) {
  const searchParams = new URLSearchParams();

  for (const key in formattedState) {
    if (Object.hasOwn(formattedState, key)) {
      const value = formattedState[key];
      if (value !== undefined && value !== formattedDefaultState[key]) {
        searchParams.append(key, value);
      }
    }
  }

  // Append the unknown keys in the original order
  for (const key in unknownState) {
    if (Object.hasOwn(unknownState, key)) {
      const value = unknownState[key];
      if (value !== undefined) {
        searchParams.append(key, value);
      }
    }
  }

  return searchParams.toString();
}

function createNextState<State extends StateShape<State>>(
  state: State,
  defaultState: State,
  action: SearchAction<State>,
): State {
  const nextState: State = {
    ...state,
    ...(typeof action === 'function' ? action(state, defaultState) : action),
  };

  for (const key in defaultState) {
    if (nextState[key] == null && Object.hasOwn(defaultState, key)) {
      nextState[key] = defaultState[key];
    }
  }

  return nextState;
}

function getUnknownState<UrlKeys extends string>(
  formattedState: FormattedState<UrlKeys>,
  parsedState: object,
): FormattedState<string> {
  const unknownState: Mutable<FormattedState<string>> = {};

  for (const key in formattedState) {
    if (
      Object.hasOwn(formattedState, key) &&
      !Object.hasOwn(parsedState, key)
    ) {
      unknownState[key] = formattedState[key];
    }
  }

  return unknownState;
}

export interface UseCustomSearchParamsOptions<
  State extends StateShape<State>,
  UrlKeys extends string = Extract<keyof State, string>,
> {
  parse: SearchParamsParser<State, UrlKeys>;
  format: SearchParamsFormatter<State, UrlKeys>;
}

export type CustomSearchParams<State extends StateShape<State>> = readonly [
  state: State,
  setState: Dispatch<SearchAction<State>>,
  extra: {
    readonly isDirty: boolean;
    readonly createSearch: (action: SearchAction<State>) => string;
  },
];

export default function useCustomSearchParams<
  State extends StateShape<State>,
  UrlKeys extends string = Extract<keyof State, string>,
>({
  parse,
  format,
}: UseCustomSearchParamsOptions<State, UrlKeys>): CustomSearchParams<State> {
  const navigate = useNavigate();
  const location = useLocation();

  const defaultState = parse({});
  const formattedDefaultState = format(defaultState);
  const rawSearch = getSearch(location);
  const formattedState = parseSearch(rawSearch);
  const state = parse(formattedState);
  const unknownState = getUnknownState(formattedState, state);

  const search = createShortSearch(
    format(state),
    formattedDefaultState,
    unknownState,
  );

  const [prevSearch, setPrevSearch] = useState(search);
  const [optimisticSearch, setOptimisticSearch] = useState(search);
  const [optimisticState, setOptimisticState] = useState(state);

  if (search !== prevSearch) {
    setPrevSearch(search);

    if (search !== optimisticSearch) {
      setOptimisticSearch(search);
      setOptimisticState(state);
    }
  }

  const [scheduleUpdate] = useDebouncedUpdate();

  useLayoutEffect(() => {
    if (rawSearch !== search) {
      // This should only happen if the URL contains default values
      scheduleUpdate(() => {
        navigate({ search }, { mode: 'replace' });
      });
    }
  });

  const stateRef = useRef<State>(state);

  useLayoutEffect(() => {
    stateRef.current = state;
  });

  const setState = useEventCallback((action: SearchAction<State>) => {
    const nextState = (stateRef.current = createNextState(
      stateRef.current,
      defaultState,
      action,
    ));

    setOptimisticState(nextState);

    const nextSearch = createShortSearch(
      format(nextState),
      formattedDefaultState,
      unknownState,
    );

    scheduleUpdate(() => {
      navigate({ search: nextSearch }, { mode: 'replace' });
    });
  });

  return [
    optimisticState,
    setState,
    {
      isDirty: optimisticSearch !== '',

      createSearch(action: SearchAction<State>) {
        return createShortSearch(
          format(createNextState(state, defaultState, action)),
          formattedDefaultState,
          unknownState,
        );
      },
    },
  ];
}

export interface CreateSearchParamsHookOptions<
  State extends object,
  UrlKeys extends string = Extract<keyof State, string>,
> {
  parse: SearchParamsParser<State, UrlKeys>;
  format: SearchParamsFormatter<State, UrlKeys>;
}

export type SearchParamsHook<State extends object> =
  () => CustomSearchParams<State>;

/**
 * Create some state that is synchronized with the current URL query string.
 * Simply pass in a pair of parse and format functions to generate a hook.
 * @deprecated Prefer using {@link useCustomSearchParams} directly instead
 */
export function createSearchParamsHook<
  State extends object,
  UrlKeys extends string = Extract<keyof State, string>,
>({
  parse,
  format,
}: CreateSearchParamsHookOptions<State, UrlKeys>): SearchParamsHook<State> {
  /** @deprecated */
  return function useSearchParamsState() {
    return useCustomSearchParams({ parse, format });
  };
}
