import type {
  PermissionGroup,
  PermissionGroups,
} from '@studio/core-common/utils/permission';
import acquireLock from '@studio/utils/acquireLock';
import { encodeBase64Url } from '@studio/utils/base64';
import createBroadcastStore from '@studio/utils/createBroadcastStore';
import { delay } from '@studio/utils/promise';
import type { RequestContext } from '@studio/utils/requestContext';

import { isRole } from '../access';
import { CLIENT_NAME, DEVICE_ID } from '../constants';
import { HttpError } from '../errors';
import type { AnyResource, DataDocument } from '../json-api';
import { apiURL, fetchWrapper } from '../utils';
import type {
  StudioIdentityBasicUserInfo,
  StudioIdentityToken,
  StudioIdentityTokenPayloadWithToken,
  StudioIdentityTokenResponse,
  StudioIdentityUserInfo,
  StudioIdentityUserInfoResponse,
  StudioIdentityUsersByGroupRequest,
  StudioIdentityUsersByRoleOrGroupResponse,
  StudioIdentityUsersReportResponse,
  StudioIdentityUsersRequest,
  StudioIdentityUsersResponse,
  UserDecoration,
  UserId,
} from './types';
import {
  extractPublishingSite,
  getTokenPayload,
  isPublishingSiteRole,
} from './utils';

export type * from './types';
export * from './utils';

// The broadcast aspect is not strictly necessary for correctness,
// but it prevents each tab from having to make its own request to get the token
export const tokenStore = createBroadcastStore<
  StudioIdentityTokenPayloadWithToken | null,
  StudioIdentityToken | null
>(
  'token',
  (state, token) => {
    if (token == null) {
      return null;
    }

    if (state?.token === token) {
      return state;
    }

    return {
      token,
      ...getTokenPayload(token),
    };
  },
  null,
);

if (import.meta.webpackHot != null) {
  import.meta.webpackHot.dispose(() => {
    tokenStore[Symbol.dispose]();
  });
}

function getTokenIfNotExpired() {
  const tokenState = tokenStore.unsafe_getState();

  if (tokenState == null || tokenState.exp * 1000 < Date.now()) {
    return null;
  }

  return tokenState;
}

export interface LoginUrlOptions {
  mode?: 'hybrid' | 'modern';
  realm: string;
  discoClient: string;
  deviceId: string;
  successPath: string;
  errorPath: string;
  redirectOrigin: string;
}

const identityApi = {
  getLoginHref(
    options: Omit<LoginUrlOptions, 'discoClient' | 'deviceId'>,
  ): string {
    const url = apiURL`/identity/v0/login`;
    url.searchParams.set(
      'config',
      encodeBase64Url(
        JSON.stringify({
          ...options,
          deviceId: DEVICE_ID,
          discoClient: CLIENT_NAME,
        } satisfies LoginUrlOptions),
      ),
    );
    return url.toString();
  },

  async getToken(
    signal: AbortSignal,
  ): Promise<StudioIdentityTokenPayloadWithToken> {
    signal.throwIfAborted();

    // Return the local token right away, if it's not expired
    {
      const tokenState = getTokenIfNotExpired();
      if (tokenState != null) {
        return tokenState;
      }
    }

    // This lock is not required for correctness, but it improves performance by using the local token if it's available
    await using _lock = await acquireLock(tokenStore.name, { signal });

    const url = apiURL`/identity/v0/token`;

    // Check it again, since the token store might have been updated by someone else
    for (let retries = 0; ; retries += 1) {
      {
        const tokenState = getTokenIfNotExpired();
        if (tokenState != null) {
          return tokenState;
        }
      }

      signal.throwIfAborted();

      // If we needed more than one attempt, there is clock skew between the server and client
      // There is not a way to force a token refresh right not, so just add a delay
      if (retries > 0) {
        await delay(1000, signal);
      }

      // TODO: This should be retried a couple of times if it fails for some random reason
      const response = await fetchWrapper(url, {
        method: 'POST',
        // Avoid recursive overflow
        skipInterceptors: true,
      });

      const { token }: StudioIdentityTokenResponse = await response.json();

      tokenStore.dispatch(token);
    }
  },

  async revokeToken(context: RequestContext): Promise<void> {
    const { signal } = context;

    signal.throwIfAborted();

    const url = apiURL`/identity/v0/token`;

    await using _lock = await acquireLock(tokenStore.name, { signal });

    try {
      await fetchWrapper(url, {
        ...context,
        method: 'DELETE',
        skipRetries: true,
        skipInterceptors: true,
      });
    } finally {
      tokenStore.dispatch(null);
    }
  },

  async revokeSonicToken(context: RequestContext | null): Promise<void> {
    const url = apiURL`/identity/v0/sonic-token`;

    await fetchWrapper(url, {
      ...context,
      method: 'DELETE',
      skipRetries: true,
      skipInterceptors: true,
    });
  },

  async getCustomAttributes(
    context: RequestContext,
    customAttributesId: string,
  ): Promise<unknown> {
    const url = apiURL`/identity/v0/custom-attributes/${customAttributesId}`;

    const response = await fetchWrapper(url, {
      ...context,
      method: 'GET',
    });

    const document: DataDocument<AnyResource> = await response.json();

    return document.data.attributes;
  },

  async setCustomAttributes(
    context: RequestContext,
    customAttributesId: string,
    customAttributes: unknown,
  ): Promise<void> {
    const url = apiURL`/identity/v0/custom-attributes/${customAttributesId}`;

    await fetchWrapper(url, {
      ...context,
      method: 'PUT',
      body: JSON.stringify({
        data: {
          type: 'userCustomAttributes',
          id: customAttributesId,
          attributes: customAttributes,
        },
      }),
    });
  },

  async getUserInfo(
    context: RequestContext,
    userId: UserId,
  ): Promise<StudioIdentityUserInfo | null> {
    const url = apiURL`/identity/v0/users/${userId}`;

    try {
      const response = await fetchWrapper(url, {
        ...context,
        method: 'GET',
      });

      const json: StudioIdentityUserInfoResponse = await response.json();

      return {
        ...json,
        roles: json.roles.filter(isRole).sort(),
        publishingSites: json.roles
          .filter(isPublishingSiteRole)
          .map(extractPublishingSite),
      };
    } catch (error) {
      if (error instanceof HttpError && error.status === 404) {
        return null;
      }
      throw error;
    }
  },

  async getUserDecoration(
    context: RequestContext,
    email: string,
  ): Promise<UserDecoration> {
    const url = apiURL`/identity/v0/users/${email}/decoration`;

    const response = await fetchWrapper(url, {
      ...context,
      method: 'GET',
    });

    return await response.json();
  },

  async getUsers(
    context: RequestContext,
    request: StudioIdentityUsersRequest,
  ): Promise<StudioIdentityUsersResponse> {
    const url = apiURL`/identity/v0/users`;

    for (const [key, value] of Object.entries(request)) {
      url.searchParams.set(key, value);
    }

    const response = await fetchWrapper(url, {
      ...context,
      method: 'GET',
    });

    return await response.json();
  },

  async getAllUsers(
    context: RequestContext,
  ): Promise<StudioIdentityUsersReportResponse> {
    const url = apiURL`/identity/v0/users-report`;

    const response = await fetchWrapper(url, {
      ...context,
      method: 'GET',
    });

    return await response.json();
  },

  async getUsersByRoleOrPermissionGroup(
    context: RequestContext,
    request: StudioIdentityUsersByGroupRequest,
  ): Promise<StudioIdentityUsersByRoleOrGroupResponse> {
    const { permissionGroup, query } = request;

    const url = apiURL`/identity/v0/roles/${permissionGroup}/users`;

    for (const [key, value] of Object.entries(query)) {
      url.searchParams.set(key, value);
    }

    const response = await fetchWrapper(url, {
      ...context,
      method: 'GET',
    });

    return await response.json();
  },

  async getPermissionGroups(
    context: RequestContext,
  ): Promise<PermissionGroups> {
    const url = apiURL`/identity/v0/permission-groups`;

    const response = await fetchWrapper(url, {
      ...context,
      method: 'GET',
    });

    const permissionGroupArray: readonly PermissionGroup[] =
      await response.json();

    return new Map(
      permissionGroupArray.map(permissionGroup => [
        permissionGroup.name,
        permissionGroup,
      ]),
    );
  },

  async getUserDetailsByTwId(
    context: RequestContext,
    twId: string,
  ): Promise<StudioIdentityBasicUserInfo | null> {
    const url = apiURL`/identity/v0/users/twid/${twId}`;

    try {
      const response = await fetchWrapper(url, {
        ...context,
        method: 'GET',
        skipRetries: true,
      });

      return await response.json();
    } catch (error) {
      if (error instanceof HttpError && error.status === 404) {
        return null;
      }

      throw error;
    }
  },
};

export default identityApi;
