import { useCallback, useState } from 'react';

import type { CustomAttributes } from '@studio/api/custom-attributes';
import { isAbortError } from '@studio/utils/abortError';
import acquireLock from '@studio/utils/acquireLock';
import createReadonlyMap from '@studio/utils/createReadonlyMap';
import isNonNullable from '@studio/utils/isNonNullable';
import { useRequestContext } from '@studio/utils/requestContext';
import useBroadcastChannel from '@studio/utils/useBroadcastChannel';
import useCachedAutoRequest from '@studio/utils/useCachedAutoRequest';
import useEventCallback from '@studio/utils/useEventCallback';
import useThrowError from '@studio/utils/useThrowError';

import { useLoginMode } from '../components/SessionManager';

interface SetAttributesOptions<Attributes extends object> {
  readonly signal?: AbortSignal | null | undefined;
  readonly onUpdateSuccess?: (updatedAttributes: Attributes) => void;
  readonly onUpdateError?: (error: unknown) => void;
}

export type UpdateAttributes<
  Attributes extends object,
  DefaultAttributes extends Attributes | null,
> = (
  key: unknown,
  updateAttributes: (
    prevAttributes: Attributes | DefaultAttributes,
  ) => Attributes,
  options?: SetAttributesOptions<Attributes>,
) => void;

export type UseCustomAttributes<
  Attributes extends object,
  DefaultAttributes extends Attributes | null,
> = readonly [
  attributes: Attributes | DefaultAttributes,
  updateAttributes: UpdateAttributes<Attributes, DefaultAttributes>,
  {
    readonly loading: boolean;
    readonly updating: boolean;
    readonly isKeyUpdating: (key: unknown) => boolean;
  },
];

export interface UseCustomAttributesOptions {
  readonly skip?: boolean;
  readonly skipBroadcast?: boolean;
}

/**
 * Manages the ownership and update mechanism of a custom attribute
 * @param customAttributes
 * @returns
 */
export default function useCustomAttributes<
  ID extends string,
  Attributes extends object,
  DefaultAttributes extends Attributes | null,
>(
  customAttributes: CustomAttributes<ID, Attributes, DefaultAttributes>,
  {
    skip: skipFetch = false,
    skipBroadcast = skipFetch,
  }: UseCustomAttributesOptions = {},
): UseCustomAttributes<Attributes, DefaultAttributes> {
  const throwError = useThrowError();
  const loginMode = useLoginMode();
  const context = useRequestContext();

  const {
    data: cachedAttributes,
    setData: setCachedAttributes,
    loading,
  } = useCachedAutoRequest(customAttributes.getAttributes, {
    params: [loginMode],
    skip: skipFetch,
  });

  const { postMessage: syncAttributes } = useBroadcastChannel<
    Attributes | DefaultAttributes
  >({
    channelName: `CUSTOM_ATTRIBUTES:${customAttributes.id}`,
    onMessage: setCachedAttributes,
    skip: skipBroadcast,
  });

  const [abortControllersById, setControllersById] = useState(
    createReadonlyMap<unknown, AbortController>,
  );

  const setAttributes = useEventCallback<
    UpdateAttributes<Attributes, DefaultAttributes>
  >(
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    async (
      key,
      updateAttributes,
      { signal: providedSignal, onUpdateSuccess, onUpdateError } = {},
    ) => {
      abortControllersById.get(key)?.abort();

      const abortController = new AbortController();

      const signal = AbortSignal.any(
        [context.signal, abortController.signal, providedSignal].filter(
          isNonNullable,
        ),
      );

      setControllersById(prevUpdating => {
        const newUpdating = new Map(prevUpdating);
        newUpdating.set(key, abortController);
        return newUpdating;
      });

      try {
        // this lock is only between tabs/windows, not global
        await using _lock = await acquireLock(
          `customAttributes:${customAttributes.id}`,
          { signal },
        );

        let prevAttributes: Attributes | DefaultAttributes | undefined;
        let nextAttributes: Attributes;

        try {
          // Always refetch the latest data, since someone else might have changed it inbetween
          prevAttributes = await customAttributes.getAttributes(
            { ...context, signal },
            loginMode,
          );

          nextAttributes = updateAttributes(prevAttributes);

          nextAttributes = await customAttributes.setAttributes(
            { ...context, signal },
            loginMode,
            nextAttributes,
          );

          // Finally update based on returned data
          syncAttributes(nextAttributes);
          setCachedAttributes(nextAttributes);
        } catch (error) {
          try {
            // If something went wrong, pull down the latest changes to make sure the cache is not out of sync
            prevAttributes = await customAttributes.getAttributes(
              // use a fresh signal, since the old one might have been aborted
              { ...context, signal: new AbortController().signal },
              loginMode,
            );
          } finally {
            if (prevAttributes !== undefined) {
              syncAttributes(prevAttributes);
              setCachedAttributes(prevAttributes);
            }
          }

          throw error;
        }

        onUpdateSuccess?.(nextAttributes);
      } catch (error) {
        if (!isAbortError(error, signal)) {
          (onUpdateError ?? throwError)(error);
        }
      } finally {
        setControllersById(prevUpdating => {
          const newUpdating = new Map(prevUpdating);
          newUpdating.delete(key);
          return newUpdating;
        });
      }
    },
  );

  return [
    cachedAttributes ?? customAttributes.defaultAttributes,
    setAttributes,
    {
      loading,
      updating: abortControllersById.size !== 0,
      isKeyUpdating: useCallback(
        (key: unknown) => abortControllersById.has(key),
        [abortControllersById],
      ),
    },
  ];
}
