import { UAParser } from 'ua-parser-js';

function getValidValue<ValidValue extends string>(
  value: string | undefined,
  validValues: readonly ValidValue[],
): ValidValue | undefined {
  if (value !== undefined && validValues.includes(value as any)) {
    return value as ValidValue;
  }
  return undefined;
}

const parser = new UAParser(navigator.userAgent);

export const OS_NAME = getValidValue(parser.getOS().name, [
  'Mac OS',
  // iPadOS reports itself as "Mac OS" by default (desktop view)
  'iOS',
  'Windows',
  'Android',
  'Chromium OS',
]);

export const OS_VERSION = parser.getOS().version;

export const BROWSER_NAME = parser.getBrowser().name;

export const BROWSER_VERSION = parser.getBrowser().version;

export const ENGINE_NAME = getValidValue(parser.getEngine().name, [
  // Chrome, Edge, Brave
  'Blink',
  // Safari
  'WebKit',
  // Firefox
  'Gecko',
]);

export function isSafari(): boolean {
  return ENGINE_NAME === 'WebKit';
}
