import * as RQ from '@tanstack/react-query';

import { isAbortError } from './abortError';
import { DEFAULT_POLL_INTERVAL } from './constants';
import id from './id';
import type { RequestContext } from './requestContext';
import StackSnapshot from './StackSnapshot';

function returnUndefined() {
  return undefined;
}

export type Updater<T> = RQ.Updater<T | undefined, T>;
export type AnyParams = readonly any[];
export type AnyData = NonNullable<unknown> | null;

export type AnyRequest<
  in Params extends AnyParams = AnyParams,
  out Data extends AnyData = AnyData,
> = (context: RequestContext, ...params: Params) => Data | PromiseLike<Data>;

export type RequestParams<Request extends AnyRequest> =
  Request extends AnyRequest<infer Params extends AnyParams, AnyData>
    ? Readonly<Params>
    : never;

export type RequestData<Request extends AnyRequest> =
  Request extends AnyRequest<AnyParams, infer Data extends AnyData>
    ? Data
    : never;

const QueryKeySymbol = Symbol('QueryKey');

export type QueryKey<Request extends AnyRequest = AnyRequest> = readonly [
  request: Request,
  context: RequestContext,
  ...params: RequestParams<Request>,
] & {
  readonly [QueryKeySymbol]: unknown;
};

export function getQueryKey<const Request extends AnyRequest>(
  request: Request,
  context: RequestContext,
  ...params: RequestParams<Request>
): QueryKey<Request> {
  const queryKey = [request, context, ...params] as const;
  // @ts-expect-error - This is needed for tagging it
  queryKey[QueryKeySymbol] = undefined;
  return queryKey as QueryKey<Request>;
}

export function isQueryKey<const Request extends AnyRequest>(
  value: unknown,
): value is QueryKey<Request> {
  return value != null && typeof value === 'object' && QueryKeySymbol in value;
}

export type QueryKeyRequest<Key extends QueryKey> =
  Key extends QueryKey<infer Request> ? Request : never;

export type QueryKeyData<Key extends QueryKey> =
  Key extends QueryKey<AnyRequest<AnyParams, infer Data>> ? Data : never;

async function queryFn<const Request extends AnyRequest>({
  queryKey,
  signal: querySignal,
}: RQ.QueryFunctionContext): Promise<RequestData<Request>> {
  if (!isQueryKey<Request>(queryKey)) {
    throw new RQ.CancelledError({ silent: true, revert: false });
  }

  const [request, context, ...params] = queryKey;

  const signal =
    querySignal == null
      ? context.signal
      : AbortSignal.any([querySignal, context.signal]);

  try {
    try {
      const data = await request({ ...context, signal }, ...params);
      return data as RequestData<Request>;
    } finally {
      signal.throwIfAborted();
    }
  } catch (error) {
    if (isAbortError(error, signal)) {
      throw new RQ.CancelledError({ revert: true });
    }
    throw error;
  }
}

interface BaseQueryOptions {
  /** If skip is true, the request will be skipped entirely */
  readonly skip?: boolean;
  /** Specifies the interval in ms at which you want your component to poll for data */
  readonly poll?: boolean | number;
  /** If the previous data should be kept while loading the next data */
  readonly keepDataWhileLoading?: boolean;
  /** If the previous data should be kept while skipping */
  readonly keepDataWhileSkipping?: boolean;
  /** The time in ms that cache data remains fresh (default 0ms) */
  readonly staleTime?: number;
  /** The time in ms that unused/inactive cache data remains in memory (default 5m) */
  readonly cacheTime?: number;
  /** If errors should be handled manually */
  readonly handleErrorManually?: boolean;
}

export interface QueryOptionsByKey<out Key extends QueryKey>
  extends BaseQueryOptions {
  readonly queryKey: Key | undefined | null;
}

export interface QueryOptions<out Request extends AnyRequest>
  extends BaseQueryOptions {
  readonly queryKey: QueryKey<Request> | undefined | null;
}

export function createUseQueryOptions<const Request extends AnyRequest>({
  queryKey,
  skip = false,
  poll = false,
  keepDataWhileLoading = false,
  keepDataWhileSkipping = false,
  handleErrorManually = false,
  staleTime = 1 * 60 * 1000,
  cacheTime = 60 * 60 * 1000,
}: QueryOptions<Request>): RQ.UseQueryOptions<RequestData<Request>> {
  skip ||= queryKey == null;

  const pollInterval =
    typeof poll === 'number' ? poll : poll ? DEFAULT_POLL_INTERVAL : undefined;

  return {
    queryKey: queryKey ?? [],
    queryFn: StackSnapshot.wrap(queryFn<Request>),
    enabled: !skip,
    staleTime,
    gcTime: cacheTime,
    refetchInterval:
      pollInterval !== undefined
        ? query =>
            // Don't refetch if we're in an error state
            query.state.status === 'error' ? false : pollInterval
        : undefined,
    placeholderData: keepDataWhileLoading ? RQ.keepPreviousData : undefined,
    throwOnError: !handleErrorManually,
    select: skip && !keepDataWhileSkipping ? (returnUndefined as never) : id,
  };
}

export default function useQuery<const Key extends QueryKey>(
  queryOptions: QueryOptionsByKey<Key>,
): RQ.UseQueryResult<QueryKeyData<Key>>;

export default function useQuery<const Request extends AnyRequest>(
  queryOptions: QueryOptions<Request>,
): RQ.UseQueryResult<RequestData<Request>>;

export default function useQuery<const Request extends AnyRequest>(
  queryOptions: QueryOptions<Request>,
): RQ.UseQueryResult<RequestData<Request>> {
  return RQ.useQuery(createUseQueryOptions(queryOptions));
}

if (import.meta.webpackHot != null) {
  import.meta.webpackHot.decline();
}
