import type { CSSProperties } from 'react';

export function toCSS(value: number | string): string {
  if (typeof value === 'number') {
    return `${value}px`;
  }

  return value;
}

export function createCheckerboardBackgroundStyles(
  size: string = '8px',
  color: string = '#ccc',
): CSSProperties {
  return {
    backgroundImage: [
      `linear-gradient(45deg, ${color} 25%, transparent 25%)`,
      `linear-gradient(-45deg, ${color} 25%, transparent 25%)`,
      `linear-gradient(45deg, transparent 75%, ${color} 75%)`,
      `linear-gradient(-45deg, transparent 75%, ${color} 75%)`,
    ].join(', '),
    backgroundSize: `calc(2 * ${size}) calc(2 * ${size})`,
    backgroundPosition: `0 0, 0 ${size}, ${size} -${size}, -${size} 0px`,
    backgroundRepeat: 'repeat',
  };
}
