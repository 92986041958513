import type { Preferences } from '@studio/api/custom-attributes';
import type { Settings } from '@studio/api/settings';
import type { CurrentUser, MfaAction, MfaStatus } from '@studio/api/users';
import type { SubdivisionStrategies } from '@studio/api/utils/subdivision';
import type { TaggedUnion } from '@studio/utils/types';

import type { ExperienceConfig } from '../../utils/experience';
import type { PermissionGroups } from '../../utils/permission';
import type { LoginMode } from './reducer';

export enum SessionActionType {
  Login = 'session/LOGIN',
  LoginSucceeded = 'session/LOGIN_SUCCEEDED',
  LoginFailed = 'session/LOGIN_FAILED',
  SetupMfa = 'session/SETUP_MFA',
  SetupMfaSucceeded = 'session/SETUP_MFA_SUCCEEDED',
  SetupMfaFailed = 'session/SETUP_MFA_FAILED',
  VerifyMfa = 'session/VERIFY_MFA',
  VerifyMfaSucceeded = 'session/VERIFY_MFA_SUCCEEDED',
  VerifyMfaFailed = 'session/VERIFY_MFA_FAILED',
  RestoreSession = 'session/RESTORE_SESSION',
  RestoreSessionSucceeded = 'session/RESTORE_SESSION_SUCCEEDED',
  RestoreSessionFailed = 'session/RESTORE_SESSION_FAILED',
  UpdateUser = 'session/UPDATE_USER',
  UpdatePreferences = 'session/UPDATE_PREFERENCES',
  Logout = 'session/LOGOUT',
  LogoutFinished = 'session/LOGOUT_FINISHED',
  Redirect = 'session/REDIRECT',
  DismissError = 'session/DISMISS_ERROR',
}

export type SessionAction = TaggedUnion<
  { type: SessionActionType },
  {
    [SessionActionType.Login]: {
      username: string;
      password: string;
      realm: string;
    };
    [SessionActionType.LoginSucceeded]: {
      mfaAction: MfaAction | null;
    };
    [SessionActionType.LoginFailed]: {
      error: Error;
    };
    [SessionActionType.SetupMfa]: {
      cellphone: string;
      countryCode: string;
    };
    [SessionActionType.SetupMfaSucceeded]: {
      qrCode: string | null;
    };
    [SessionActionType.SetupMfaFailed]: {
      error: Error;
    };
    [SessionActionType.VerifyMfa]: unknown;
    [SessionActionType.VerifyMfaSucceeded]: {
      mfaStatus: MfaStatus;
    };
    [SessionActionType.VerifyMfaFailed]: {
      error: Error;
    };
    [SessionActionType.RestoreSession]: {
      ignoreErrors: boolean;
      lastRealm: string | null;
    };
    [SessionActionType.RestoreSessionSucceeded]: {
      mode: LoginMode;
      user: CurrentUser;
      subdivisionStrategies: SubdivisionStrategies | null;
      permissionGroups: PermissionGroups | null;
      experienceConfig: ExperienceConfig | null;
      preferences: Preferences;
      settings: Settings;
    };
    [SessionActionType.RestoreSessionFailed]: {
      error: Error;
    };
    [SessionActionType.UpdateUser]: {
      user: CurrentUser;
    };
    [SessionActionType.UpdatePreferences]: {
      preferences: Partial<Preferences>;
    };
    [SessionActionType.Logout]: unknown;
    [SessionActionType.LogoutFinished]: unknown;
    [SessionActionType.Redirect]: {
      url: string;
    };
    [SessionActionType.DismissError]: unknown;
  }
>;
