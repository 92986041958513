import { Role } from '../roles';
import { and, not, or, ref as rawRef, type Rule } from '../RuleEngine';
import type { AccessContext } from '../utils';
import { CurationPromotionFeature } from './curationPromotionFeatures';

const GLOBAL_BOLT_REALM = and(
  { realm: 'bolt' },
  not({ publishingSite: 'beam_us' }),
);

// We can't do this unfortunately, since it triggers a recursion error
// const ref = rawRef<keyof typeof rules>;
const ref = rawRef<
  // Feature rule names
  | CurationPromotionFeature
  // Role rule names
  | keyof typeof roleRules
  // Any other string starting with either "has" or "can"
  | `has${string}`
  | `can${string}`
  | `is${string}`
>;

const roleRules = {
  isMerchandiser: { roles: Role.ContentPromoter },
  isMerchandisingReader: {
    roles: Role.ContentPromoterReader,
  },
} as const satisfies Record<`is${Capitalize<string>}`, Rule<AccessContext>>;

const featureFlagRules = {
  [CurationPromotionFeature.CurationPromotion]: and(GLOBAL_BOLT_REALM, {
    env: 'test',
  }),
  [CurationPromotionFeature.CurationPromotionSettings]: GLOBAL_BOLT_REALM,
};

const permissionRules = {
  canAccessCurationPromotion: and(
    ref(CurationPromotionFeature.CurationPromotion),
    or(
      ref('isSuperAdmin'),
      ref('isSonicDeveloper'),
      ref('isMerchandiser'),
      ref('isMerchandisingReader'),
    ),
  ),

  canAccessCurationPromotionSettings: and(
    ref(CurationPromotionFeature.CurationPromotionSettings),
    or(ref('isSuperAdmin'), ref('isSonicDeveloper')),
  ),
} as const satisfies Record<`can${Capitalize<string>}`, Rule<AccessContext>>;

const curationPromotionRules = {
  ...featureFlagRules,
  ...permissionRules,
  ...roleRules,
};

export default curationPromotionRules;
