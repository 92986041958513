import { promiseProps } from '@studio/utils/promise';
import type { RequestContext } from '@studio/utils/requestContext';

import type { Preferences } from './types';
import {
  createCustomAttributes,
  type CustomAttributes,
  type LoginMode,
} from './utils';
import {
  isCmsBookmarks,
  isCmsEditorHistory,
  isInfoPanelPreference,
  isInitialContentSubset,
  isInitialProductLine,
  isInitialPublishingSite,
  isMultiverseStorage,
  isPersistedExperience,
  isRecentlyViewed,
  isRegionPreference,
  isSavedItems,
  isTablePreference,
  isTableStorage,
  isTimeZonePreference,
} from './validators';

export * from './types';

export { type CustomAttributes, type LoginMode };

const customAttributesApi = {
  experience: createCustomAttributes(
    'studio.experience',
    isPersistedExperience,
  ),

  timeZonePreference: createCustomAttributes(
    'sonic.studio.time-zone-preference',
    isTimeZonePreference,
    { type: 'realm' },
  ),

  regionPreference: createCustomAttributes(
    'sonic.studio.region-preference',
    isRegionPreference,
    { region: 'en-international' },
  ),

  tablePreference: createCustomAttributes(
    'sonic.studio.table-preference',
    isTablePreference,
    { rowsPerPage: 30 },
  ),

  infoPanelPreference: createCustomAttributes(
    'sonic.studio.infopanel-preference',
    isInfoPanelPreference,
    { collapsed: false },
  ),

  recentlyViewed: createCustomAttributes(
    'sonic.studio.recently-viewed',
    isRecentlyViewed,
    { entries: [] },
  ),

  savedItems: createCustomAttributes('sonic.studio.saved-items', isSavedItems, {
    savedItems: [],
  }),

  cmsBookmarks: createCustomAttributes(
    'sonic.cms-ui.bookmarks',
    isCmsBookmarks,
    { version: 2, bookmarks: [] },
  ),

  cmsEditorHistory: createCustomAttributes(
    'sonic.cms-ui.editor-history',
    isCmsEditorHistory,
    { version: 1, recentlyOpened: [] },
  ),

  initialPublishingSite: createCustomAttributes(
    'sonic.cms-ui.initial-publishing-site',
    isInitialPublishingSite,
  ),

  initialProductLine: createCustomAttributes(
    'sonic.cms-ui.initial-product-line',
    isInitialProductLine,
  ),

  initialContentSubset: createCustomAttributes(
    'sonic.vms-ui.initial-content-subset',
    isInitialContentSubset,
  ),

  multiverseStorage: createCustomAttributes(
    'sonic.studio.multiverse',
    isMultiverseStorage,
    { allowList: [], autoEnroll: [], releaseEnroll: [] },
  ),

  tableStorage: createCustomAttributes('sonic.studio.tables', isTableStorage),

  getPreferences(
    context: RequestContext,
    mode: LoginMode,
  ): Promise<Preferences> {
    const {
      timeZonePreference,
      tablePreference,
      regionPreference,
      infoPanelPreference,
    } = customAttributesApi;

    return promiseProps({
      timeZone: timeZonePreference.getAttributes(context, mode),
      table: tablePreference.getAttributes(context, mode),
      region: regionPreference.getAttributes(context, mode),
      infoPanel: infoPanelPreference.getAttributes(context, mode),
    });
  },
};

export default customAttributesApi;
