export function retainSet<T, V extends T>(
  values: Set<T>,
  filterFn: (value: T) => value is V,
): asserts values is Set<V> {
  for (const value of values) {
    if (!filterFn(value)) {
      values.delete(value);
    }
  }
}
