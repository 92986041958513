/**
 * A map that holds weak references to its values. When a value is garbage collected,
 * the corresponding entry in the map is removed.
 *
 * It is important to note that the keys are not weakly held, so the map will not
 * prevent the keys from being garbage collected.
 *
 * @example
 * import WeakValueMap from '@studio/utils/WeakValueMap';
 *
 * const map = new WeakValueMap<string, WeakKey>();
 * let key = {};
 * map.set('key', key);
 * map.has('key'); // true
 * key = null;
 * global.gc();
 * map.has('key'); // false
 */
export default class WeakValueMap<K, V extends WeakKey> {
  static readonly #finalizer = new FinalizationRegistry<{
    readonly map: WeakValueMap<unknown, WeakKey>;
    readonly key: unknown;
    readonly ref: WeakRef<WeakKey>;
  }>(({ map, key, ref }) => {
    if (map.#map.get(key) === ref) {
      map.#map.delete(key);
    }
  });

  readonly #map = new Map<K, WeakRef<V>>();

  has(key: K): boolean {
    return this.get(key) !== undefined;
  }

  get(key: K): V | undefined {
    return this.#map.get(key)?.deref();
  }

  set(key: K, value: V): void {
    let ref = this.#map.get(key);
    if (ref !== undefined) {
      WeakValueMap.#finalizer.unregister(ref);
    }
    ref = new WeakRef(value);
    this.#map.set(key, ref);
    WeakValueMap.#finalizer.register(value, { map: this, key, ref }, ref);
  }

  delete(key: K): void {
    const ref = this.#map.get(key);
    if (ref !== undefined) {
      WeakValueMap.#finalizer.unregister(ref);
    }
    this.#map.delete(key);
  }
}
