import { createNotifier } from '@studio/utils/createNotifier';
import type { Store } from '@studio/utils/useStoreState';

import type { AppConfig, Apps } from './types';

export type * from './types';

class AppStore implements Store<Apps> {
  #apps: Apps = new Map();

  readonly #notifier = createNotifier();
  readonly subscribe = this.#notifier.listen;
  readonly unsafe_getState = () => this.#apps;

  install(app: AppConfig): void {
    if (this.#apps.has(app.key)) {
      throw new Error(`🚨 An app with key ${app.key} already exists!`);
    }

    const nextApps = new Map(this.#apps);
    nextApps.set(app.key, app);
    this.#apps = nextApps;
    this.#notifier.notify();
  }

  clear(): void {
    this.#apps = new Map();
    this.#notifier.notify();
  }
}

const appStore = new AppStore();

export default appStore;
