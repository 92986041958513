import { useMemo } from 'react';

import { isRole } from '@studio/api/access';
import type { Preferences } from '@studio/api/custom-attributes';
import {
  extractPublishingSite,
  isPublishingSiteRole,
} from '@studio/api/identity';
import type { Inlined } from '@studio/api/json-api';
import type { Settings } from '@studio/api/settings';
import type { CurrentUser, UmsUiConfig } from '@studio/api/users';
import type { EnvironmentConfig } from '@studio/utils/fromEnvironment';

import { createExperienceRoleResolver } from '../../utils/experience';
import type { PermissionGroups } from '../../utils/permission';
import { useExperienceContext } from '../ExperienceManager';
import { useSessionState } from './contexts';
import { LoginMode, type SessionState, SessionStateStatus } from './reducer';

function selectLoggedInSessionState(sessionState: SessionState) {
  if (sessionState.status !== SessionStateStatus.LoggedIn) {
    return undefined;
  }
  return sessionState;
}

function selectLoggedInSessionStateOrThrow(sessionState: SessionState) {
  if (sessionState.status !== SessionStateStatus.LoggedIn) {
    throw new Error('You must be logged-in to access logged-in state');
  }
  return sessionState;
}

export function selectUser(
  sessionState: SessionState,
): CurrentUser | undefined {
  return selectLoggedInSessionState(sessionState)?.user;
}

export function selectLoginMode(sessionState: SessionState): LoginMode {
  return selectLoggedInSessionState(sessionState)?.mode ?? LoginMode.Modern;
}

function selectRealm(sessionState: SessionState): string | undefined {
  return selectUser(sessionState)?.realm;
}

function selectSettings(sessionState: SessionState): Settings {
  return selectLoggedInSessionStateOrThrow(sessionState).settings;
}

function selectUmsUiConfig(sessionState: SessionState): Inlined<UmsUiConfig> {
  return selectSettings(sessionState).umsUiConfig;
}

export function selectRealmTimeZone(sessionState: SessionState): string {
  return selectSettings(sessionState).studioConfig.locale.timeZone;
}

export function selectPreferences(sessionState: SessionState): Preferences {
  return selectLoggedInSessionStateOrThrow(sessionState).preferences;
}

export function selectPermissionGroups(
  sessionState: SessionState,
): PermissionGroups | undefined {
  return (
    selectLoggedInSessionState(sessionState)?.permissionGroups ?? undefined
  );
}

function selectEnvironment(
  sessionState: SessionState,
): EnvironmentConfig | undefined {
  return (
    selectLoggedInSessionState(sessionState)?.settings?.environment ?? undefined
  );
}

export interface UseUserOptions {
  readonly resolveExperienceRoles?: boolean;
}

// If we could pass a selector to useContext, we could use our selectors instead
// https://github.com/reactjs/rfcs/pull/119

export function useUser({
  resolveExperienceRoles = true,
}: UseUserOptions = {}): CurrentUser | undefined {
  const sessionState = useSessionState();

  const user = selectUser(sessionState);
  const permissionGroups = selectPermissionGroups(sessionState);

  const roleResolver = useMemo(() => {
    if (!resolveExperienceRoles || user == null || permissionGroups == null) {
      return null;
    }

    return createExperienceRoleResolver(
      user.legacyRoles,
      user.groups,
      permissionGroups,
    );
  }, [permissionGroups, resolveExperienceRoles, user]);

  const experienceContext = useExperienceContext();
  const experience = experienceContext?.experience;

  return useMemo(() => {
    if (user == null || roleResolver == null || experience == null) {
      return user;
    }

    const resolvedRoles = roleResolver(experience);

    return {
      ...user,
      roles: resolvedRoles.values().filter(isRole).toArray(),
      allowedPublishingSites: resolvedRoles
        .values()
        .filter(isPublishingSiteRole)
        .map(extractPublishingSite)
        .toArray(),
    };
  }, [experience, roleResolver, user]);
}

export function useUserRaw(): CurrentUser | undefined {
  return selectUser(useSessionState());
}

export function useLoginMode(): LoginMode {
  return selectLoginMode(useSessionState());
}

export function useRealm(): string | undefined {
  return selectRealm(useSessionState());
}

export function useRealmTimeZone(): string | undefined {
  return selectRealmTimeZone(useSessionState());
}

export function useUmsUiConfig(): Inlined<UmsUiConfig> {
  return selectUmsUiConfig(useSessionState());
}

export function usePreferences(): Preferences {
  return selectPreferences(useSessionState());
}

export function useEnvironmentConfigSafely(): EnvironmentConfig | undefined {
  return selectEnvironment(useSessionState());
}

export function useEnvironmentConfig(): EnvironmentConfig {
  const environment = useEnvironmentConfigSafely();
  if (environment === undefined) {
    throw new Error('Environment is not available');
  }
  return environment;
}

export function usePermissionGroups(): PermissionGroups | undefined {
  return selectPermissionGroups(useSessionState());
}
