/* eslint-disable no-console */

declare global {
  interface Task {
    run<R>(fn: () => R): R;
  }

  interface Console {
    /**
     * Async Stack Tagging API
     * @link https://developer.chrome.com/blog/devtools-modern-web-debugging#more_improvements_to_stack_traces
     */
    createTask?(name: string): Task;
  }
}

class FakeTask implements Task {
  readonly #name: string;

  get name() {
    return this.#name;
  }

  constructor(name: string) {
    this.#name = name;
  }

  run<R>(fn: () => R): R {
    return fn();
  }
}

function isPromiseLike(value: unknown): value is PromiseLike<unknown> {
  return (
    value !== null &&
    typeof value === 'object' &&
    'then' in value &&
    typeof value.then === 'function'
  );
}

function isErrorLike(value: unknown): value is Error {
  return (
    value !== null &&
    typeof value === 'object' &&
    'message' in value &&
    typeof value.message === 'string'
  );
}

type AnyFn = (...args: readonly any[]) => any;

export default class StackSnapshot implements Task {
  static wrap<Fn extends AnyFn>(
    this: typeof StackSnapshot,
    fn: Fn,
  ): (...args: Parameters<Fn>) => ReturnType<Fn> {
    const snapshot = new this(fn.name, this.wrap);
    return (snapshot.run<ReturnType<Fn>, Parameters<Fn>>).bind(snapshot, fn);
  }

  readonly #task: Task;
  readonly #stack: string | null;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-function-type
  constructor(name: string, topOfStack: Function = new.target) {
    name ||= 'unknown';
    this.#task = console.createTask?.(name) ?? new FakeTask(name);
    try {
      throw new Error();
    } catch (error: any) {
      Error.captureStackTrace?.(error, topOfStack);
      this.#stack = error.stack ?? null;
      // This _should_ work with V8, JSC and SpiderMonkey stacks
      if (this.#stack?.startsWith(error.name)) {
        this.#stack = this.#stack.slice(error.name.length);
      }
    }
  }

  #appendStack(error: unknown) {
    if (isErrorLike(error) && error.stack != null && this.#stack != null) {
      error.stack += this.#stack;
    }
  }

  run<R, Args extends readonly any[]>(
    fn: (...args: Args) => R,
    ...args: Args
  ): R {
    try {
      const result =
        args.length === 0
          ? this.#task.run(fn)
          : this.#task.run(
              fn.bind<undefined, [...Args], [], R>(undefined, ...args),
            );

      if (isPromiseLike(result)) {
        // @ts-expect-error - It's a promise
        return result.then(undefined, error => {
          this.#appendStack(error);
          throw error;
        });
      }

      return result;
    } catch (error) {
      this.#appendStack(error);
      throw error;
    }
  }
}
