import * as History from 'history';

/** The combined pathname + search + hash */
export type Path = History.Path;

/** The pathname */
export type Pathname = History.Pathname;

/** An object representing the parsed path */
export type Location = History.Location<unknown>;

export type To =
  | Path
  | Partial<Location>
  | ((location: Location) => Path | Partial<Location>);

export const DEFAULT_LOCATION = Object.freeze(History.createLocation('/'));

function isLocation(to: To): to is Location {
  return (
    typeof to === 'object' &&
    to.pathname !== undefined &&
    to.search !== undefined &&
    to.hash !== undefined
  );
}

export function resolvePath(from: Location, to?: To | null): Path {
  return History.createPath(resolveLocation(from, to));
}

export function resolvePathname(from: Location, to?: To | null): Pathname {
  if (to == null) {
    return from.pathname;
  }

  switch (typeof to) {
    case 'function':
      return resolvePathname(from, to(from));

    case 'string':
      return to;

    default:
      return to.pathname ?? from.pathname;
  }
}

function withPrefixIfNotEmpty(prefix: string, value: string): string {
  return value === '' || value.startsWith(prefix) ? value : `${prefix}${value}`;
}

function resolveLocationHelper(from: Location, to?: To | null): Location {
  if (to == null) {
    return from;
  }

  if (from.state === undefined && isLocation(to)) {
    return to;
  }

  switch (typeof to) {
    case 'function':
      return resolveLocation(from, to(from));

    case 'string':
      return resolveLocation(from, History.parsePath(to));

    default:
      return {
        pathname: to.pathname ?? from.pathname,
        search: withPrefixIfNotEmpty('?', to.search ?? from.search),
        hash: withPrefixIfNotEmpty('#', to.hash ?? from.hash),
        state: to.state ?? from.state,
        // Clear it, since this is a new location
        key: undefined,
      };
  }
}

export function resolveLocation(
  from: Location,
  ...tos: readonly (To | null | undefined)[]
): Location {
  return tos.reduce(resolveLocationHelper, from);
}

export function resolveTo(...tos: readonly (To | null | undefined)[]): To {
  return location => tos.reduce(resolveLocationHelper, location);
}
