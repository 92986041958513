import { matchPath } from '@studio/router';

import { portalRoot } from '../appRoutes';
import appStore, { type AppConfig } from '../appStore';

function createMatchObject(app: AppConfig) {
  return {
    name: app.title,
    route: app.route.pattern,
  };
}

const installedApps = appStore.unsafe_getState();

// Portal which is just '/' should go after all the other routes
const allAppRoutes = [
  ...installedApps.values().map(createMatchObject),
  { route: portalRoot.pattern, name: 'Portal' },
];
export function getAppNameFromPath(pathname: string): string {
  return (
    allAppRoutes.find(appName => matchPath(pathname, appName.route) !== null)
      ?.name ?? 'Unknown'
  );
}
