import {
  type AccessContext,
  type AccessFn,
  isRole,
  Role,
} from '@studio/api/access';
import { getPublishingSiteRole } from '@studio/api/identity';

import type { ExperienceRoleResolver } from './roles';
import type { AuthorizedExperience, Experience } from './types';
import { retainSet } from './utils';

export type ExperienceAuthorizationChecker = <
  SomeExperience extends Experience,
>(
  experience: SomeExperience,
) => experience is SomeExperience & AuthorizedExperience;

export function createExperienceAuthorizationChecker(
  access: AccessFn,
  currentAccessContext: Partial<AccessContext>,
  resolveExperienceRoles: ExperienceRoleResolver,
): ExperienceAuthorizationChecker {
  return function checkExperienceAuthorization<
    SomeExperience extends Experience,
  >(
    experience: SomeExperience,
  ): experience is SomeExperience & AuthorizedExperience {
    const experienceRoles = resolveExperienceRoles(experience);

    // Special publishing site access logic
    if (
      experience.publishingSite != null &&
      !experienceRoles.has(Role.Admin) &&
      !experienceRoles.has(getPublishingSiteRole(experience.publishingSite))
    ) {
      return false;
    }

    // Remove unknown roles
    retainSet(experienceRoles, isRole);

    const accessContext = getExperienceAccessContext(
      currentAccessContext,
      experience,
      experienceRoles,
    );

    return access(accessContext);
  };
}

export function getExperienceAccessContext(
  currentAccessContext: Partial<AccessContext>,
  experience: Experience,
  roles: ReadonlySet<Role>,
): Partial<AccessContext> {
  return {
    ...currentAccessContext,
    roles,
    studioExperience: experience.studioExperience,
    subdivisionTenant: experience.subdivisionTenant ?? undefined,
    subdivisionMarket: experience.subdivisionMarket ?? undefined,
    publishingSite: experience.publishingSite ?? undefined,
  };
}
