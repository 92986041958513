import {
  isSubsetOfContext,
  type PermissionGroupName,
  type PermissionGroups,
} from '../permission';
import type { Experience } from './types';

export type ExperienceRoleResolver = (experience: Experience) => Set<string>;

export function createExperienceRoleResolver(
  userLegacyRoleNames: Iterable<string>,
  userPermissionGroupNames: Iterable<PermissionGroupName>,
  permissionGroups: PermissionGroups,
): ExperienceRoleResolver {
  return function resolveExperienceRoles(experience: Experience): Set<string> {
    const resolvedRoleNames = new Set<string>();

    // Treat the legacy roles as having no conditions
    // TODO: only apply them if experience.studioExperience === "max"
    for (const legacyRoleName of userLegacyRoleNames) {
      resolvedRoleNames.add(legacyRoleName);
    }

    for (const groupName of userPermissionGroupNames) {
      const group = permissionGroups.get(groupName);
      if (group != null) {
        for (const role of group.roles) {
          if (!resolvedRoleNames.has(role.name)) {
            const mergedConditions =
              role.conditions != null
                ? { ...group.conditions, ...role.conditions }
                : group.conditions;

            if (isSubsetOfContext(mergedConditions, experience)) {
              resolvedRoleNames.add(role.name);
            }
          }
        }
      }
    }

    return resolvedRoleNames;
  };
}
