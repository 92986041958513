import { useMemo, useRef } from 'react';

import { DEFAULT_DEBOUNCE_TIMEOUT } from './constants';

export interface DebouncedUpdateOptions {
  debounceTimeout?: number;
}

export default function useDebouncedUpdate({
  debounceTimeout = DEFAULT_DEBOUNCE_TIMEOUT,
}: DebouncedUpdateOptions = {}): readonly [
  schedule: (callback: () => void) => void,
  flush: () => void,
  cancel: () => void,
] {
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const callbackRef = useRef<() => void>();

  return useMemo(() => {
    function cancel() {
      if (timeoutRef.current !== undefined) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = undefined;
      }
      callbackRef.current = undefined;
    }

    function flush() {
      const callback = callbackRef.current;
      cancel();
      callback?.();
    }

    function schedule(callback: () => void) {
      cancel();
      timeoutRef.current = setTimeout(flush, debounceTimeout);
      callbackRef.current = callback;
    }

    return [schedule, flush, cancel];
  }, [debounceTimeout]);
}
