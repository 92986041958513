import type { ReactElement } from 'react';
import * as RR from 'react-router';

import { resolveLocation, type To } from './utils';

export interface RedirectProps extends Omit<RR.RedirectProps, 'to'> {
  to: To;
}

export default function Redirect(props: RedirectProps): ReactElement {
  const location = RR.useLocation();
  return <RR.Redirect {...props} to={resolveLocation(location, props.to)} />;
}
