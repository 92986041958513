import * as RQ from '@tanstack/react-query';
import {
  createElement,
  type ReactElement,
  type ReactNode,
  useRef,
} from 'react';

import memoizeWithWeakMap from './memoizeWithWeakMap';
import type { RequestContext } from './requestContext';
import {
  type AnyData,
  type AnyRequest,
  isQueryKey,
  type QueryKey,
  type QueryKeyData,
  type RequestData,
  type Updater,
} from './useQuery';

export interface QueryCacheProviderProps {
  children?: ReactNode;
}

const getFunctionId = memoizeWithWeakMap((obj: { name: string }) => {
  const randomSuffix = Math.random().toString(36).slice(7).padEnd(6, '0');
  return `${obj.name}-${randomSuffix}`;
});

function getRequestContextJSON({
  publishingSiteId,
  experience,
}: RequestContext): Record<string, string | undefined> {
  return {
    subdivisionTenant: experience?.subdivisionTenant ?? undefined,
    subdivisionMarket: experience?.subdivisionMarket ?? undefined,
    publishingSite: experience?.publishingSite ?? publishingSiteId ?? undefined,
    commerceLine: experience?.commerceLine ?? undefined,
    productLine: experience?.productLine ?? undefined,
  };
}

function queryKeyHashFn(queryKey: unknown): string {
  if (isQueryKey(queryKey)) {
    const [request, context, ...params] = queryKey;

    return RQ.hashKey([
      getFunctionId(request),
      getRequestContextJSON(context),
      ...params,
    ]);
  }

  if (Array.isArray(queryKey)) {
    return RQ.hashKey(queryKey);
  }

  return '';
}

function createQueryClient(): RQ.QueryClient {
  return new RQ.QueryClient({
    defaultOptions: {
      queries: {
        // We already retry in fetchWrapper
        retry: false,
        // This causes a stack overflow for recursive data structures
        structuralSharing: false,
        refetchOnWindowFocus: false,
        queryKeyHashFn,
      },
    },
  });
}

export function QueryCacheProvider({
  children,
}: QueryCacheProviderProps): ReactElement {
  const client = (useRef<RQ.QueryClient>().current ??= createQueryClient());
  return createElement(RQ.QueryClientProvider, { client }, children);
}

export interface QueryCache {
  unsafe_getQueryData<const Key extends QueryKey>(
    queryKey: Key,
  ): QueryKeyData<Key> | undefined;
  unsafe_getQueryData<const Request extends AnyRequest>(
    queryKey: QueryKey<Request>,
  ): RequestData<Request> | undefined;

  setQueryData<const Key extends QueryKey>(
    queryKey: Key,
    updater: Updater<QueryKeyData<Key>>,
  ): QueryKeyData<Key> | undefined;
  setQueryData<const Request extends AnyRequest>(
    queryKey: QueryKey<Request>,
    updater: Updater<RequestData<Request>>,
  ): RequestData<Request> | undefined;

  refetchQuery<const Request extends AnyRequest>(
    queryKey: QueryKey<Request>,
  ): void;
  invalidateQuery<const Request extends AnyRequest>(
    queryKey: QueryKey<Request>,
  ): void;
}

export const getQueryCache = memoizeWithWeakMap(
  (queryClient: RQ.QueryClient): QueryCache => ({
    unsafe_getQueryData(queryKey: QueryKey) {
      return queryClient.getQueryData(queryKey);
    },

    setQueryData(queryKey: QueryKey, updater: Updater<AnyData>) {
      return queryClient.setQueryData(queryKey, updater);
    },

    refetchQuery(queryKey) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.refetchQueries({ queryKey });
    },

    invalidateQuery(queryKey) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      queryClient.invalidateQueries({ queryKey });
    },
  }),
);

export default function useQueryCache(): QueryCache {
  const queryClient = RQ.useQueryClient();
  return getQueryCache(queryClient);
}
